<template>
  <div :class="{ 'mb-4': isSmallerScreen }">
    <!-- <div>
        <div class="post-header flex justify-between mb-2">
          <div class="flex items-center">
            <div class="vx-card__title mb-3">
              <h5>Bitácora de eventos</h5>
              <vs-button v-if="events.length > 0 && showExpanded" size="small" icon-pack="feather" type="border" icon="icon-check-circle" @click="showFullLog">Ver últimos movimientos</vs-button>
            </div>
          </div>
          <div class="flex" v-if="!showExpanded">
            <vs-button v-if="events.length > 0" size="small" icon-pack="feather" type="border" icon="icon-check-circle" @click="showFullLog">Ver últimos movimientos</vs-button>
          </div>
      </div>

      <div v-if="showExpanded && events.length > 0" class="comments-container mt-2">
        <ul class="user-comments-list">
          <li
            v-for="(log, l) in events"
            :key="l"
            class="commented-user flex items-center mb-1"
          >
            <div class="leading-tight">
              <p class="bold mb-1 text-md bold-dark">{{ logFrom(log) }}</p>
              <span class="text-xmd" style="white-space: pre-wrap">{{ escapedText(log.description) }}</span>
              <div class="flex items-left mt-1">
                 <vx-tooltip
                  color="dark"
                  position="right"
                  :text="logDateFormat(log.created_at)">
                  <feather-icon class="mr-2 time-tag-icon" icon="ClockIcon" svgClasses="h-3 w-3"></feather-icon> 
                  <span class="text-xs time-tag">{{ timeAgoFormat(log.created_at) }}</span>
                </vx-tooltip>
              </div>
              <vs-divider></vs-divider>         
            </div>            
          </li>
        </ul>
      </div>

      <div v-else-if="!showExpanded && events.length > 0">
        <vs-table stripe :data="events" :noDataText="noEventsText">
          <template slot="thead">
            <vs-th class="bold">ACTOR</vs-th>
            <vs-th class="bold">ACCIÓN</vs-th>
            <vs-th class="bold"></vs-th>
          </template>

<template slot-scope="{data}">
            <template v-for="(tr, indextr) in data">
              <vs-tr v-if="!validateToShowEvents(tr.description,1)" :data="tr" :key="indextr" >
                <vs-td class="text-sm">
                  {{ logFrom(tr)}}
                </vs-td>

                <vs-td  class="text-sm">
                  {{ escapedText(tr.description)}}
                </vs-td>

                <vs-td >
                  <vx-tooltip
                    color="dark"
                    position="top"
                    :text="logDateFormat(tr.created_at)">
                    <span class="text-xs time-tag">{{ timeAgoFormat(tr.created_at) }}</span>
                  </vx-tooltip>
                </vs-td>
              </vs-tr>
            </template>
</template>
</vs-table>
</div>

<div v-else>
  <p>{{noEventsText}}</p>
</div>

</div> -->
    <div class="vx-col w-full flex clickable-img">
      <h5 @click.stop="showFullLog()" class="mt-2">
        <u>
          Ver últimos movimientos
        </u>
      </h5>
      <div @click.stop="showFullLog()" class="relative">
        <feather-icon icon="BellIcon" class="w-10 h-10 ml-2 text-dark"></feather-icon>
        <span class="absolute notification-pill">{{ notifCount }}</span>
      </div>
    </div>
    <vs-popup fullscreen title="Todos los eventos de revisión del proyecto" :active.sync="showAllEventsPopup">
      <div class="con-exemple-prompt">

        <vs-table stripe :data="allEvents" :noDataText="noEventsText">
          <template slot="thead">
            <vs-th class="bold">ACTOR</vs-th>
            <vs-th class="bold">ACCIÓN</vs-th>
            <vs-th class="bold">FECHA EVENTO</vs-th>
          </template>

          <template slot-scope="{data}">
            <template v-for="(tr, indextr) in data">
              <vs-tr v-if="!validateToShowEvents(tr.description, 1)" :data="tr" :key="indextr">
                <vs-td class="text-md">
                  {{ logFrom(tr) }}
                </vs-td>

                <vs-td class="text-md">
                  {{ escapedText(tr.description) }}
                </vs-td>

                <vs-td class="text-md">
                  {{ logDateFormat(tr.created_at) }}
                </vs-td>
              </vs-tr>
            </template>
          </template>
        </vs-table>
      </div>
    </vs-popup>

  </div>
</template>

<script>
import dateHelper from "@mixins/dateHelper";
import { mapState } from 'vuex'

export default {
  name: "credit-events-log",
  mixins: [dateHelper],
  props: ["projectId"],
  data() {
    return {
      previewLimit: 5,
      showAllEventsPopup: false,
      allEvents: [],
      events: [],
      noEventsText: "No se han registrado eventos",
      notifCount: 0
    };
  },
  async mounted() {
    await this.getEvents();
  },
  computed: {
    ...mapState("auth", ["user"]),
    lastSession : function () {
      return (this.user === null) ? "-" : this.user.last_login;
    },
    showExpanded() {
      return this.isBackoffice;
    },
    isBackoffice() {
      return process.env.VUE_APP_IS_BACKOFFICE == 'true';
    },
    isSmallerScreen() {
      return this.$store.state.windowWidth <= 1200
    }
  },
  methods: {
    async getEvents() {
      try {
        this.showLoading(true);
        let path = "/getCreditRequestEvents";
        let response = await axios.get(`/api/v1/project/${this.projectId}${path}`);
        this.allEvents = response.data;
        this.calculateNotReadEvents();
        this.events = this.allEvents.slice(0, this.previewLimit);
        this.showLoading(false);

      } catch (e) {
        this.warn(e);
      }
    },
    calculateNotReadEvents() {
      let notifCount = 0;
      this.allEvents.forEach((event) => {
        if(this.isAfter(event.created_at, this.lastSession, "days")) {
          notifCount++;
        }
      });

      this.notifCount = notifCount;

      if(this.notifCount > 9) {
        this.notifCount = "9+";
      }

    },
    async showFullLog() {
      await this.getEvents();
      this.showAllEventsPopup = true;
    },
    logFrom(event) {
      if (event.supplier_id != null) {
        return "Proveedor"
      }
      else if (event.rg_user_id != null) {
        let r = "RedGirasol";
        if (this.isBackoffice) {
          r += ` (${event.rg_user_info.email})`;
        }
        return r;
      }
      else if (event.client_id != null) {
        return "Solicitante"
      }
    },
    entryClass(uid) {
      return this.userId == uid ? "mine" : "other";
    },
    escapedText(t) {
      return t.split('<br />').join('\n');
    },
    iconForEvent(event_int) {

    },
    validateToShowEvents(text, type) {
      let event = "";
      switch (type) {
        case 1:
          event = "Se desverificó documento: Reporte crediticio";
          break;
      }
      return text.includes(event);
    }
  }
};
</script>

<style scoped>
.log-table {
  border-collapse: separate;
  border-spacing: 6px;
}

.log-icon {
  text-align: center;
}

td.log-content {
  text-align: left;
  border: 1px solid;
  width: 100%;
}

td.empty-log {
  text-align: center;
  border: 1px solid;
  background-color: #e8e8e8;

  font-style: italic;
}

.log-from {
  margin-right: 5px;
}

td.log-date {
  text-align: right;
  min-width: 120px;
  font-size: 11px;
  font-family: "gilroybold";
  vertical-align: bottom;
  border: none;
  color: white;
}

.log-entry.mine .log-date {
  background-color: #c0d760;
}

.log-entry.other .log-date {
  background-color: #fece6b;
}

.time-tag,
.time-tag-icon {
  color: rgba(var(--vs-primary), 1) !important;
}

.notification-pill {
  left: 20px;
  /* top: 1px; */
  background-color: red;
  color: #fff;
  /* font-size: small; */
  font-weight: 600;
  height: 1rem;
  width: 1rem;
  border-radius: 10px;
  font-size: 10px;
}
</style>