<template>
    <div v-if="isMounted">
      <div class="vx-row" v-if="isMounted && hasContracts">
        <div class="vx-col w-full mb-6">
          <vx-card title="Resumen de contratos">
            <div class="vx-row">
              <div v-for="(group, index) in grouped_contracts" :key="index"
                class="vx-col xl:w-25% lg:w-1/4 md:w-full  sm:w-full ">
                <project-contract-progress v-if="index != 4" :contract_group="group" :index="index"
                  :contract_groups="contract_groups" :project="project" :hasSerialNumbers="hasSerialNumbers" />
              </div>
            </div>
          </vx-card>
        </div>
      </div>

      <vx-card v-if="!hasContracts" title="Antes de firmar tus contratos">
        <p>Requerimos que nos proporciones las facturas, así como los datos técnicos para poder generar los contratos.
          Si ya nos proporcionaste dicha información el equipo de RedGirasol está en proceso de generar los contratos y te
            aparecerán en este apartado en los próximos días. <br><br>
        </p>

        <p v-if="!isMaster">
          Una vez que los contratos hayan sido firmados, RedGirasol procederá a realizarte el pago para iniciar con la entrega del proyecto.
        </p>

        <p v-if="isMaster">
          Una vez que los contratos de cada fase hayan sido firmados, RedGirasol procederá a realizarte el pago para iniciar con la entrega correspondiente
          a dicha fase.<br><br>
        </p>
        
        <p>
          Recuerda que para la firma autógrafa digital debes hacerlo desde un dispositivo móvil (celular o tablet) y vas a requerir un código que es enviado por SMS al celular indicado en la tabla de firmantes. 
          El Solicitante para la firma del Contrato de Crédito va a requerir su identificación oficial (credencial del INE/IFE). 
          <a class="bold" @click="goToTutorial()" target="_blank">Aquí puedes ver un tutorial sobre cómo firmar un contrato con firma autógrafa digital. </a>
        </p> <br>

<!-- 
        <p class="bold" v-if="isMaster">
          Es también relevante considerar que los contratos de todas las fases de tu proyecto tendrán que ser firmadas de la misma forma que definas a continuación:
        </p> -->

<!-- 
        <div class="vx-row mt-base">
          <div class="vx-col w-3/4">
            <label for="" class="vs-input--label">{{ label }}</label>
            <v-select
              label="text"
              name="contract_signing_wish"
              class="vs-custom w-full mb-5"
              v-model.lazy="contract_signing_wish"
              :options="optionsForSelect"
              :reduce="text => text.value"
              :clearable="false"
              :searchable="false"
              :disabled="isDisabled"
              placeholder="Seleccione una opción" 
            >
            </v-select>
          </div>
        </div> -->

        <!-- Save Button -->
        <!-- <div class="vx-row mt-3" v-if="!isDisabled">
          <div class="vx-col w-full">
            <div class="flex justify-between flex-wrap">
                <vs-button :disabled="!contract_signing_wish" class="mt-4" color="primary" @click="saveWish()" >Guardar</vs-button>
            </div>
          </div>
        </div> -->

      </vx-card>

      <div v-else>
        <template v-if="!isMaster">
          <contracts-table :id="project.id" :project="project" :client="project.client" />
        </template>

        <template v-else >
          <contracts-table v-for="split in project.splits" :key="split.id" :id="project.id" :project="split" :client="project.client"  />
        </template>
        
        <vx-card v-if="contract_signing_wish == 1 && hasContracts" title="Servicio de mensajería" style="overflow: auto;">
          <div class="vx-row">
            <div class="vx-col w-3/5">
              <label for="" class="vs-input--label">Servicio de mensajería</label>
                <v-select
                  label="text"
                  name="contract_signing_wish"
                  class="vs-custom w-full mb-5"
                  v-model="deliverySelected"
                  :options="deliveryOptionsForSelect"
                  :reduce="text => text.value"
                  :clearable="false"
                  :searchable="true"
                  :disabled="project.delivery_service_type != null"
                  placeholder="Seleccione una opción" 
                >
                <div slot="no-options">No hay coincidencias</div>
                </v-select>
              </div>

              <div class="vx-col w-2/5">
                <vs-input
                  :disabled="project.delivery_service_type != null"
                  v-if="deliverySelected == 10" 
                  class="w-full mb-5"
                  v-model="otherDelivery"
                  label="Otro servicio de mensajería" />
              </div>
              <div class="vx-col w-3/5">
                <vs-input 
                  :disabled="(deliverySelected == 1 || deliverySelected == null) || project.delivery_service_type != null"
                  class="w-full mb-5"
                  v-model="trackingNumber"
                  label="Número de guía" />
              </div>
          </div>

          <!-- Save Button -->
          <div class="vx-row mt-3" v-if="!isDisabled">
            <div class="vx-col w-full">
              <div class="flex justify-between flex-wrap">
                  <vs-button :disabled="deliverySelected == null || project.delivery_service_type != null" class="mt-4" color="primary" @click="saveTrackingInfo()" >Guardar</vs-button>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
  </div>
</template>

<script>
import ContractsTable from '@projects/ContractsTable.vue'
import ProjectContractProgress from '@components/supplier/projects/ProjectContractProgress.vue'
  const tutorialFADLink = "https://youtu.be/19npgHmTUi0";

export default {
  name: 'contracts-control',
  props: ['project_id', 'isDisabled'],
  components: {
    ContractsTable,
    ProjectContractProgress
  },
  data(){
    return{
      project: {},
      contracts: [],
      isMounted: false,
      isMaster: false,

      contract_signing_wish: null,

      optionsForSelect: [
        {
          value: 1,
          text: "Firma autógrafa (proceso manual)" 
        },
        // {
        //   value: 2,
        //   text: "Firma electrónica a través de MIFIEL con la efirma" 
        // },
        {
          value: 3,
          text: "Firma electrónica a través la Firma Autógrafa Digital (FAD)" 
        }
      ],

      //delivery info
      deliveryOptionsForSelect: [
        {
          value: 1,
          text: "Mensajería propia"
        },
        {
          value: 2,
          text: "Correos de México" 
        },
        {
          value: 3,
          text: "DHL" 
        },
        {
          value: 4,
          text: "Estafeta" 
        },
        {
          value: 5,
          text: "FedEx" 
        },
        {
          value: 6,
          text: "PaqueteExpress" 
        },
        {
          value: 7,
          text: "RedPack" 
        },
        {
          value: 8,
          text: "Sendex Paquetería" 
        },
        {
          value: 9,
          text: "UPS" 
        },
        {
          value: 10,
          text: "Otro servicio" 
        },
      ],
      deliverySelected: null, 
      otherDelivery: null,
      trackingNumber: "",
      tutorialFADLink: tutorialFADLink,

      groupedContracts: [],
      contract_groups: [],
      serial_numbers: [],

    }
  },
  computed: {
    hasContracts(){
      return this.contracts.length > 0;
    },    
    accessToken(){
      return this.access_token;
    },
    label(){
      if(this.isMaster){
        return "¿Cómo deseas que sean firmados los contratos para todas las fases del proyecto?"
      }
      else {
        return "¿Cómo deseas que sean firmados los contratos?"
      }
    },
    grouped_contracts() {
      if (this.isMounted) {
        let g1 = [];
        let g2 = [];
        let g3 = [];
        let g4 = [];
        let generic = [];
        let groupedContracts = [];
        this.contracts.forEach(contract => {
          switch (contract.contract_group_id) {
            case 1: g1.push(contract); break;
            case 2: g2.push(contract); break;
            case 3: g3.push(contract); break;
            case 4: g4.push(contract); break;
            default:
              generic.push(contract);
            // this.hasOldContracts = true;
          }
        });
        groupedContracts = [g1, g2, g3, g4, generic];
        // console.log(this.groupedContracts);
        return groupedContracts
      } else {
        return [];
      }
    },
    hasSerialNumbers() {
      if (this.isMounted && this.serial_numbers.length != 0) {
        this.serial_numbers.forEach(sn => {
          if (sn.serial_numbers_count < 1) {
            return false;
          }
        });
        return true;
      } else {
        return false;
      }
    },
  },
  async mounted(){
    this.isMounted = false;
    this.showLoading(true);
    await this.getContractsInfo();
    this.showLoading(false);
    this.isMounted = true
  },
  methods:{
    signatories(contract){
      if (contract.signatories_json){
        let ss = contract.signatories_json;
        return ss.join(', ');
      }
      else {
        return "--";
      }      
    },
    async getContractsInfo(){
      let params = "with[]=contracts&with[]=client.user&with[]=splits.contracts";
      let response = await axios.get(`/api/v2/projects/${this.project_id}?${params}`);
      this.project = response.data;
      this.isMaster = this.project.splits.length > 0;
      this.contracts = response.data.contracts;
      this.contract_signing_wish = this.project.signing_type_wish;
      this.deliverySelected = this.project.delivery_service_type;
      this.otherDelivery = this.project.delivery_service_other;
      this.trackingNumber = this.project.delivery_tracking_id;
    },
    async saveWish(){
      this.showLoading(true)
      try {
        let payload = {signing_type_wish: this.contract_signing_wish};
        await axios.put(`/api/v2/projects/${this.project_id}/deepUpdate`, payload);
        await this.getContractsInfo();        
        this.saveSuccessNotif();
      }
      catch (error) {
        this.warn(error);
        this.failedOperationNotif();
      }
      this.showLoading(false)
    },
    async saveTrackingInfo(){
      this.showLoading(true);
      let payload = {};
      if(this.deliverySelected != null || this.trackingNumber != ""){
        try {
          payload = {
            delivery_service_type: this.deliverySelected,
            delivery_service_other: this.deliverySelected == 10 ? this.otherDelivery : null,
            delivery_tracking_id: this.deliverySelected != 1 ? this.trackingNumber : null
          }
          await axios.put(`/api/v2/projects/${this.project_id}/deepUpdate`, payload);
          await this.getContractsInfo(); 
          this.saveSuccessNotif();

        } catch(error) {
          this.warn(error);
          this.failedOperationNotif();
        }
      } else {
        this.missingFieldsNotif();
      }
      this.showLoading(false);
    },
    goToTutorial(){
      try{
        window.open(this.tutorialFADLink,"_blank");
      } catch (err) {
        console.error("Algo salió mal con el link del tutorial.", err);
        this.errorNotif();
      }
    },
    getSerialNumbers() {
      axios.get(`/api/v1/fg_locations/${this.project_id}/getSerialNumbers`).then(response => {
        this.serial_numbers = response.data;
        // console.log(response.data);
      });
    },
  }
}
</script>