<template>

  <div class="vx-col w-full" v-if="isMounted">
    <div class="vx-row">
      <div class="vx-col w-full mb-4 ml-2">
        <p v-if="hasBackofficeAccess">Al aprobar el archivo PDF generará automáticamente el retiro al Proveedor correspondiente al bono.</p>
        <div v-if="!hasBackofficeAccess">
          <p>
            <span class="bold">¡Felicidades! Este proyecto te generó a favor una comisión, por lo que RedGirasol te realizará el pago de la misma.</span>
              Para lo anterior es necesario que nos envíes la factura con la siguiente información:
          </p>
          <p class="bold mt-3">Datos fiscales: </p>
          <p class="ml-3 leading-mid-loose">
            <span class="mr-2">- Empresa:</span>RED GIRASOL SAPI DE CV <br>
            <span class="mr-2">- RFC:</span>RGI160414TR9 <br>
            <span class="mr-2">- Dirección:</span>Av. Eugenio Garza Sada 3820 Int 801, Colonia Más Palomas, Monterrey, N.L., C.P. 64780<br>
            <span class="mr-2">- Forma de pago:</span>(31) Intermediarios<br>
            <span class="mr-2">- Método de pago:</span>(PUE) Pago en una sola exhibición<br>
            <span class="mr-2">- Uso de CFDI:</span>Gastos en General<br>
          </p>
          <p class="bold mt-3">Datos a facturar: </p>
          <p class="ml-3 leading-mid-loose">
            <span class="mr-2">- Clave de producto:</span>80141628 <br>
            <span class="mr-2">- Concepto:</span>Servicio de distribuidores por comisión. Comisión proyecto {{project_alias}} PID: {{project_id}}<br>
            <span class="mr-2">- Subtotal:</span>{{explicitMoneyFormat(s173_amount)}}<br>
            <span class="mr-2">- IVA:</span>{{explicitMoneyFormat(s176_amount)}}<br>
            <span class="mr-2">- Total:</span>{{explicitMoneyFormat(total)}}<br>
          </p>

          <p class="mt-4 mb-2">
            Una vez hayas subido la factura (PDF y XML), en 5 días hábiles estarás recibiendo el pago en tu cuenta de banco.
          </p>
        </div>
      </div>
    </div>
    <div class="vx-row" >
      <document-upload
        :target_id="project_id"
        :f="pdfFormField"
        :doc="pdfDocumentSet"
        :loadedState="pdfState"
        :disabled="isDisabled"
        :inputClassLarge="true"
        :from_project_id="project_id"
        :evaluator="evaluateCondition"
        @on-verified="onPdfVerified"
      />

      <document-upload
        :target_id="project_id"
        :f="xmlFormField"
        :doc="xmlDocumentSet"
        :loadedState="xmlState"
        :disabled="isDisabled"
        :inputClassLarge="true"
        :from_project_id="project_id"
        :evaluator="evaluateCondition"
      />
    </div>

  </div>

</template>

<script>
import inputHelper from '@components/mixins/inputHelper';
import dateHelper from '@components/mixins/dateHelper';
import formHelper from '@components/mixins/formHelper';
import formatHelper from '@components/mixins/formatHelper';
export default {
  name: 'impacta-mas-invoices-control',
  props: ['project_id', 'isDisabled', 'project_alias', 's173_amount', 's176_amount', 'total'],
  mixins: [formHelper, inputHelper, dateHelper, formatHelper],
  data(){
    return {
      section_id: 53,
      pdf_id: 118,
      xml_id: 119,
      isMounted: false,
      documents: [],

      pdfState: null,
      xmlState: null,
      section: {},

      pdfFormField: {},
      xmlFormField: {},

      pdfDocumentSet: {},
      xmlDocumentSet: {},

      project: {},
    }
  },
  computed: {
    project_exists(){
      return true;
    },
    apiStorageBasePrefix(){
      return this.ApiDomain + "/storagev3/documents/";
    },
    hasBackofficeAccess(){
      return this.$acl.check('isBackoffice');
    },
  },
  async mounted(){
    this.isMounted = false;
    this.showLoading(true);
    await this.getFieldsDefinition();
    await this.getInvoicesSaved();
    this.showLoading(false);
    this.isMounted = true;
  },
  methods: {
    async getInvoicesSaved(){
      try {
        let docs = [];
        let pdfDef = {doc_id: this.pdf_id, target_id: this.project_id, classification: "project", has_assoc: false, doc_assoc: false};
        let xmlDef = {doc_id: this.xml_id, target_id: this.project_id, classification: "project", has_assoc: false, doc_assoc: false};
        docs.push(pdfDef);
        docs.push(xmlDef);

        let url = this.ApiDomain + '/storagev3/documents/files/getBulkDocumentFiles';
        let response = await axios.post(url, {bulk: docs});

        const states = response.data;
        const pdf_res = states.find(f => f.doc_id === this.pdf_id);
        const xml_res = states.find(f => f.doc_id === this.xml_id);
        if(pdf_res && pdf_res.files && pdf_res.length > 0 ){
          this.pdfState = pdf_res.files[0];
        }
        if(xml_res && xml_res.files && xml_res.length > 0 ){
          this.xmlState = xml_res.files[0];
        }
      }
      catch (error) {
        this.warn(error);
      }
    },
    async getFieldsDefinition(){
      try{
        let response = await axios.get("/api/v1/forms/getSectionFields/" + this.section_id);
        let url = this.ApiDomain + `/storagev3/documents/section/${this.section_id}`;
        let response2 = await axios.get(url);
        this.pdfDocumentSet = response2.data.find(f => f.id === this.pdf_id);
        this.xmlDocumentSet = response2.data.find(f => f.id === this.xml_id);

        this.section = response.data.section;
        this.pdfFormField = this.section.fields.find(f => f.doc_id === this.pdf_id);
        this.pdfFormField.doc_id = this.pdf_id;

        this.xmlFormField = this.section.fields.find(f => f.doc_id === this.xml_id);
        this.xmlFormField.doc_id = this.xml_id;
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    async onPdfVerified(data){
      this.$emit("on-pdf-verified");
    },
    evaluateCondition(condition){
      return this.evaluateConditionBase(this, condition);
    },
    /* obtener ids para los casos correspondientes */
    docTargetEvaluator(cls, cls_2){
      if(cls == 'project'){
        return this.project_id;
      }
      this.warn(`DOC TARGET NOT DEFINED FOR classifications: ${cls},${cls_2}`);
      return null;
    },
  }
}
</script>