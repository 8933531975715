<template>
  <vx-card title="Pago inicial" v-if="!initialPaymentDone">
    <p>¡Felicidades! Tu cliente ha aceptado el crédito. Las condiciones del crédito son las siguientes: <br><br></p>

    <p><strong>Fecha de aceptación del crédito: </strong>{{acceptance_date}}</p>
    <p><strong>Mensualidad: </strong>{{monthly_payment}} MXN</p>
    <p><strong>Tasa anual: </strong>{{annual_percentage}}</p>
    <p><strong>Plazo: </strong> {{term}} meses</p>
    <vs-divider></vs-divider>

    <p>Ahora solo esperamos que tu cliente realice el pago inicial.</p>

  </vx-card>

  <vx-card title="Pago inicial" v-else >
    <p>¡Felicidades! Tu cliente ha aceptado el crédito y ha realizado el pago inicial. Las condiciones del crédito son las siguientes: <br><br></p>

    <p><strong>Fecha de aceptación del crédito: </strong>{{acceptance_date}}</p>
    <p><strong>Mensualidad: </strong>{{monthly_payment}} MXN</p>
    <p><strong>Tasa anual: </strong>{{annual_percentage}}</p>
    <p><strong>Plazo: </strong> {{term}} meses</p>
    <p><strong>Fecha en que se realizó el pago inicial: </strong>{{initial_payment_date}}</p>
    <br>

  </vx-card>
</template>
<script>
import formatHelper from '@components/mixins/formatHelper';
import dateHelper from '@components/mixins/dateHelper';
export default {
  name: 'activate-account',
  props: ['applicantUserId', 'project_id', 'project'],
  mixins: [formatHelper, dateHelper],
  data(){
    return {
      info: null,
      isMounted: false,
    }
  },
  async mounted(){
    this.isMounted = false;
    this.showLoading(true);
    await this.getPaymentInfo();
    this.showLoading(false);
    this.isMounted = true;
  },
  computed:{
    formStatus(){
      if(this.info){
        if(this.info.status >= 5){
          return "Pagado";
        }
        else if(this.info.availble && this.info.status < 5 ){
          return "Pendiente"
        }
        else if(this.info.status < 5) {
          return "Vencida";
        }
      }
      return "-";
    },
    initialPaymentDone(){
      return this.project.initial_payment_date != null;
    },
    hasFinance(){
      return this.project.finance != null;
    },
    finance(){
      return this.project.finance;
    },
    monthly_payment(){
      if(this.hasFinance){
        return this.explicitMoneyFormat( this.finance.monthly_payment);
      }
    },
    annual_percentage(){
      if(this.hasFinance){
        return this.prcgFormat(this.finance.annual_interest_percentage * 100, 2);
      }
    },
    term(){
      if(this.hasFinance){
        return this.finance.term;
      }
    },
    acceptance_date(){
      if(this.hasFinance){
        return this.dateTime12Format(this.finance.credit_acceptance_date);
      }
    },
    initial_payment_date(){
      return this.dateTime12Format(this.project.initial_payment_date);
    }
  },
  methods: {
    async getPaymentInfo(){
      try {        
        let res = await axios.get('/api/prefund/' + this.project_id);
        this.info = res.data;
      } catch (error) {
        this.warn(error);
      }
    },
    async downloadInitialPaymentForm(){
      if(this.info.file){
        window.open(this.info.file, "_blank");
      }
    },
    async sendPaymentForm(){
      this.showLoading(true);
      try {
        await axios.get('/api/prefund/sendFirst/' + this.project_id);
        this.successNotif("Correo enviado", "Se ha enviado el correo exitosamente", 8000);
      }
      catch (error) {
        this.warn(error);
        this.errorNotif("Error en el envío", "Ha ocurrido un error al intentar enviar el correo electrónico, por favor intente más tarde", 8000);
      }
      this.showLoading(false);
    },
    timeout(ms){
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }
}
</script>