<template>
  <div class="vx-col w-full mb-5" >
    <h6 class="mb-6">{{f.indication}}</h6>

    <vs-table stripe :data="pfg_locations" noDataText="No se han generado registros">
      <template slot="thead">
        <vs-th class="bold">UBICACIÓN</vs-th>
        <vs-th class="bold">SISTEMA O TECNOLOGÍA</vs-th>
        <vs-th class="bold">ACCIONES</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">

          <vs-td :data="data[indextr].id">
            {{ addressForLocation(data[indextr]) }}
          </vs-td>

          <vs-td :data="data[indextr].family_group_id">
            {{ uppercase(data[indextr].family_group.name) }}
          </vs-td>

          <vs-td :data="data[indextr].id">
            <feather-icon v-if="!hasBackofficeAccess && !isDisabled" icon="EditIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" @click.stop="showItemRequest(data[indextr])" />
            <feather-icon v-if="hasBackofficeAccess || isDisabled" icon="EyeIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" @click.stop="showItemRequest(data[indextr])" />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

  </div>

</template>

<script>
import inputHelper from "@mixins/inputHelper"

export default {
  name: 'technology-location-technical-control',
  mixins: [inputHelper],
  props: {
    project_id: {type: [String, Number], required: true},
    f: { type: Object, required: true },
    isDisabled: { type: [String,Boolean], default: false },
  },
  data(){
    return {
      pfg_locations: [],

      showFormPrompt: false,
      selectedTechnology: null,
      project: {},
    }
  },
  beforeMount: async function(){
    await this.loadSavedProjectFgLocations();
  },
  computed: {
    pendingVerificationRequest(){
      return this.project.evidence_verification_request_date == null
    },
    evidencesVerified(){
      return this.project.complete_evidence_date != null
    },
    hasBackofficeAccess(){
      return this.$acl.check('isBackoffice');
    },
  },
  methods: {
    addressForLocation(pfg_location){
      if(pfg_location.location && pfg_location.location.address){
        let addr = pfg_location.location.address.full_address;
        if(addr.length > 60){
          return addr.substring(0, 60) + "...";
        }
        else {
          return addr;
        }
      }
      else{
        return "Sin definir";
      }
    },
    async loadSavedProjectFgLocations(){
      try {
        let res = await axios.get(`/api/v1/project/${this.project_id}/getProjectFgLocations`);
        this.pfg_locations = res.data;

        let res2 = await axios.get(`/api/v2/projects/${this.project_id}/`);
        this.project = res2.data;
      }
      catch (e) {
        this.warn(e);
      }
    },
    showItemRequest(pfg_location){
      this.$router.push({ name: 'tecnicosFgLocationProyecto', params: { id: this.project_id, fg_id: pfg_location.id } })
    }
  }
}
</script>