<template>
<div class="vx-col w-full" v-if="isMounted">
   <div class="vx-row">
      <document-upload
        v-for="(df, index) in documentsStates"
        :key="df.id"
        :target_id="project_id"
        :f="formField"
        :doc="documentSet"
        :loadedState="df.stateDef"
        :multi_assoc_index="index"
        :disabled="isDisabled"
        :from_project_id="project_id"
        :evaluator="evaluateCondition"
        />
    </div>

    <!-- Add Button -->
    <div class="vx-row" v-if="!isDisabled && !hasBackofficeAccess">
      <div class="vx-col w-full">
        <div class="flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="addImage()">Agregar imagen</vs-button>                    
        </div>
      </div>
    </div>
</div>
    
</template>

<script>
import inputHelper from '@components/mixins/inputHelper';
import dateHelper from '@components/mixins/dateHelper';
import formHelper from '@components/mixins/formHelper';
export default {
  name: 'complementary-images',
  props: ['project_id', 'isDisabled'],
  mixins: [formHelper, inputHelper, dateHelper],
  data(){
    return {
      section_id: 43,
      doc_id: 62,
      isMounted: false,
      documents: [],
      documentsStates: [],
      images: [],
      documentFiles: [],
      section: {},
      formField: {},

      documentSet: {},

      hasAssoc: null,

      docAssoc: {
        table: null,
        assoc_key: null,
        condition: null,
        assoc_source: null,
        neg_mssg: null,
        multi_assoc: null,
      },
    }
  },
  computed: {
    project_exists(){
      return true;
    },
    apiStorageBasePrefix(){
      return this.ApiDomain + "/storagev3/documents/";
    },
    hasBackofficeAccess(){
      return this.$acl.check('isBackoffice');
    },
  },
  async mounted(){
    this.isMounted = false;
    this.showLoading(true);
    await this.getFieldsDefinition();
    await this.getImagesSaved();
    this.showLoading(false);
    this.isMounted = true;
  },
  methods: {
    async getImagesSaved(){
      try {
        let params = "with[]=complementaryImages";
        let response = await axios.get(`/api/v2/projects/${this.project_id}?${params}`);
        if(!response.data){
          return;
        }

        this.images = response.data.complementary_images;

        // informacion de archivos
        let url = this.apiStorageBasePrefix;
        let getUrl = `${url}files/${this.doc_id}/getFilesByAssociated`;
        let params2 = `?assoc_table=${this.docAssoc.table}&assoc_key=${this.docAssoc.assoc_key}&assoc_id=${this.project_id}`;
        getUrl += params2;

        let res = await axios.get(getUrl);
        this.documentFiles = res.data;

        this.documentsStates = [];
        this.documentFiles.forEach(df => {
          let img = this.images.find(f => f.document_id == df.id);
          this.documentsStates.push({stateDef: df, image: img});
        }); 

      }
      catch (error) {
        this.warn(error);
      }
    },
    async getFieldsDefinition(){
      try{
        let response = await axios.get("/api/v1/forms/getSectionFields/" + this.section_id);
        let url = this.ApiDomain + `/storagev3/documents/section/${this.section_id}`;
        let response2 = await axios.get(url);
        this.documentSet = response2.data[0];
  
        this.section = response.data.section;
        this.formField = this.section.fields[0];
        this.formField.div_class = "lg:w-1/3 md:w-1/2 sm:w-3/4 w-full";
        this.formField.doc_id = this.doc_id;

        if(this.formField.extras){
          let extras = JSON.parse(this.formField.extras);
          if(extras.doc_assoc){
            this.hasAssoc = true;
            this.docAssoc.table = extras.doc_assoc.table;
            this.docAssoc.assoc_key = extras.doc_assoc.assoc_key;
            this.docAssoc.condition = extras.doc_assoc.condition;
            this.docAssoc.assoc_source = extras.doc_assoc.assoc_source;
            this.docAssoc.neg_mssg = extras.doc_assoc.neg_mssg;
            this.docAssoc.multi_assoc = extras.doc_assoc.multi_assoc || false;
          }
        }
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    addImage(){
      let ss = {stateDef: null, image: null};
      this.documentsStates.push(ss);
    },

    onFieldChange(method){
      if(method != null && method in this){
        this[method]();
      }
    },
    evaluateCondition(condition){
     return this.evaluateConditionBase(this, condition);
    },
    /* obtener ids para los casos correspondientes */
    docTargetEvaluator(cls, cls_2){
      if(cls == 'project'){
        return this.project_id;        
      }
      this.warn(`DOC TARGET NOT DEFINED FOR classifications: ${cls},${cls_2}`);
      return null;
    },
  }
}
</script>