<template>
   <vx-card :title="label" class="mb-5">
      <div v-if="manual && hasContracts" >
        <p> <strong>Tipo de firma:</strong> Manual.</p><br>
        <p>En este apartado podrás completar la firma de contratos para continuar con el proceso de instalación y entrega del proyecto. Si deseas consultar más información sobre cuándo y cuáles contratos tienen que ser firmados, además de quienes deberán hacerlo, da clic <a class="bold" style="cursor:pointer;" @click="popUpInformation = true">aquí</a>.</p>
        <p><br>
          Se recomienda ampliamente que los documentos sean firmados con firma autógrafa digital, ya que este proceso solo dura unos minutos, acelera los tiempos para el pago al Proveedor y elimina el tiempo y costo de la papelería y mensajería. 
          <a class="bold" @click="goToTutorial()" target="_blank">¿Tienes dudas? Consulta este tutorial sobre cómo firmar un contrato con Firma Autógrafa Digital (FAD) dando clic aquí. </a>
          <br> <strong>Si deseas cambiarlo a esquema de firma autógrafa digital contáctanos vía Chat o por whatsapp para hacer el cambio.</strong>
        </p><br>

        <div v-if="hasOldContracts">
          <vs-table class="mt-6" ref="table" stripe :data="project.contracts" noDataText="No hay datos disponibles">          
           <template slot="thead">
              <vs-th class="bold" sort-key="model">NOMBRE</vs-th>
              <vs-th class="bold" sort-key="brand">FECHA DE CREACIÓN</vs-th>
              <vs-th class="bold" sort-key="product_group_name">ESTADO</vs-th>
              <vs-th class="bold" sort-key="resource_impact">FECHA DE FIRMA</vs-th>
              <vs-th class="bold" sort-key="capacity">FIRMANTES</vs-th>
              <vs-th class="bold">ACCIONES</vs-th>

            </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr">
              <vs-td>
                {{ tr.type.name }}
              </vs-td>
              <vs-td>
                {{ tr.created_at }}
              </vs-td>
              <vs-td>
                {{ status(tr) }}
              </vs-td>
              <vs-td>
                {{ project.contracts_date }}
              </vs-td>
              <vs-td>
                <ul :key="name" v-for="name in signatories(tr)" style="">
                  <li>
                    {{name}}
                  </li>
                </ul>
              </vs-td>

              <vs-td :data="tr.id">
                 <div class="vx-row">
                     <vs-button v-if="tr.status != 'signed' "
                        size="small"
                     type="border" class="mb-2" target 
                     :href="$sanitizeUrl(`${ApiDomain}/storage/private/contracts/${tr.id}/${tr.contract_not_signed_file}?access_token=${accessToken}`)" >
                     Descargar PDF<br>no firmado
                     </vs-button>

                     <vs-button v-if="tr.status == 'signed'" 
                        size="small" type="border" class="mb-2" target color="success"
                        :href="$sanitizeUrl(`${ApiDomain}/storage/private/contracts/${tr.id}/${tr.contract_signed_file}?access_token=${accessToken}`)" >
                        Descargar PDF<br>firmado
                     </vs-button>
                 </div>
              </vs-td>
              
            </vs-tr>
          </template>
        </vs-table>
        </div>
        <div v-else>
          <template v-for="(group, key) in groupedContracts">
            <vx-card v-if="group.length != 0" class="mb-6" :key="key" :title="`Grupo ${group[0].group.name}`" >
              <p><strong>Nota:</strong> {{getDisclaimerLabel(key+1)}}</p>
              <p class="bold primary mt-4 ">Detalle de contratos</p>
              <div class="vx-row">
                <div class="vx-col lg:w-1/3 md:w-full sm:w-full" style="padding-left: 3rem;">
                  <p class="bold mt-4 mb-2">Documentos</p>
                  <ul class="ul-bullet" v-for="(document, index) in group" :key="index">
                    <li>
                      {{document.type.name}}
                    </li>
                  </ul>
                </div>
                <div class="vx-col lg:w-1/3 md:w-full sm:w-full"  style="padding-left: 3rem;">
                  <p class="bold mt-4 mb-2">Información adicional</p>
                  <ul class="ul-bullet">
                    <li>
                      <strong>Creado(s): </strong>{{ regularDateFormat(group[0].created_at)}}
                    </li>
                    <li>
                      <strong>Estado: </strong>{{status(group[0])}}
                    </li>
                    <li>
                      <strong>Tipo de firma: </strong>{{signing_type(group[0].signing_type)}}
                    </li>
                    <li>
                      <strong>Firmante(s): </strong>
                      <ul :key="name" v-for="name in signatories(group[0])" style="">
                          <li>
                            {{name}}
                          </li>
                        </ul>
                    </li>
                  </ul>
                </div>
                <div class="vx-col lg:w-1/3 md:w-full sm:w-full" style="padding-left: 3rem;">
                <p class="bold mt-4 mb-2">Acciones</p>
                  <vs-button v-if="group[0].status != 'signed' " 
                    size="small" type="border" class="mb-2 mr-3" target
                    @click="handleDownload(group[0].id, group[0].contract_not_signed_file)">
                    Descargar PDF<br>no firmado
                  </vs-button>
                  
                  <vs-button v-if="group[0].status == 'signed'" 
                    size="small" type="border" class="mb-2 mr-3" target color="success"
                    @click="handleDownload(group[0].id, group[0].contract_signed_file)">
                    Descargar PDF<br>firmado
                  </vs-button>

                  <vs-button v-if="group[0].status == 'signed'" 
                    size="small" type="border" class="mb-2" target color="success"
                    @click="handleDownload(group[0].id, group[0].contract_signed_xml_file)">
                    Descargar XML<br>firmado
                  </vs-button>
                </div>
              </div>
            </vx-card>
          </template>
        </div>
      </div>

      <div v-else-if="electronic && hasContracts">
        <p> <strong>Tipo de firma:</strong> Electrónica. <br><br></p>
        <p>Se enviará un correo a <strong>{{client.user.email}}</strong> con las ligas para que pueda realizar la firma de manera electrónica<br><br> </p>

        <vs-table ref="table" stripe :data="project.contracts" noDataText="No hay datos disponibles">          
           <template slot="thead">
              <vs-th class="bold" sort-key="model">NOMBRE</vs-th>
              <vs-th class="bold" sort-key="brand">FECHA DE CREACIÓN</vs-th>
              <vs-th class="bold" sort-key="product_group_name">ESTADO</vs-th>
              <vs-th class="bold" sort-key="resource_impact">FECHA DE FIRMA</vs-th>
              <vs-th class="bold" sort-key="capacity">FIRMANTES</vs-th>
              <vs-th class="bold">ACCIONES</vs-th>

            </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr">
              <vs-td>
                {{ tr.type.name }}
              </vs-td>
              <vs-td>
                {{ tr.created_at }}
              </vs-td>
              <vs-td>
                {{ status(tr) }}
              </vs-td>
              <vs-td>
                {{ project.contracts_date }}
              </vs-td>
              <vs-td>
                <template v-for="name in signatories(tr)">
                  {{name}}<br :key="name">
                </template>
              </vs-td>

              <vs-td :data="tr.id">
                 <div class="vx-row">
                  <vs-button v-if="tr.status != 'signed' " 
                     size="small" type="border" class="mb-2 mr-3" target 
                     :href="$sanitizeUrl(`${ApiDomain}/storage/private/contracts/${tr.id}/${tr.contract_not_signed_file}?access_token=${accessToken}`)" >
                     Descargar PDF<br>no firmado
                  </vs-button>
                  
                  <vs-button v-if="tr.status != 'signed' && signatoriesContainsSupplier(tr)" size="small" type="border" class="mb-2" @click="signRequest(tr)" >Firmar</vs-button>
                  
                  <vs-button v-if="tr.status == 'signed'" 
                     size="small" type="border" class="mb-2 mr-3" target color="success"
                     :href="$sanitizeUrl(`${ApiDomain}/storage/private/contracts/${tr.id}/${tr.contract_signed_file}?access_token=${accessToken}`)" >
                     Descargar PDF<br>firmado
                  </vs-button>

                  <vs-button v-if="tr.status == 'signed'" 
                     size="small" type="border" class="mb-2" target color="success"
                     :href="$sanitizeUrl(`${ApiDomain}/storage/private/contracts/${tr.id}/${tr.contract_signed_xml_file}?access_token=${accessToken}`)" >
                     Descargar XML<br>firmado
                  </vs-button>

                 </div>
              </vs-td>
              
            </vs-tr>
          </template>
        </vs-table>
      </div>

      <div v-else-if="electronicFAD && hasContracts">
        <p> <strong>Tipo de firma:</strong> Firma Autógrafa Digital (FAD). </p><br>
        <p>En este apartado podrás completar la firma de contratos para continuar con el proceso de instalación y entrega del proyecto. Si deseas consultar más información sobre cuándo y cuáles contratos tienen que ser firmados, además de quienes deberán hacerlo, da clic <a class="bold" style="cursor:pointer;" @click="popUpInformation = true">aquí</a>.</p>
        <p class="mt-4">
          Considera que necesitarás un dispositivo móvil (celular o tablet) para realizar la Firma Autógrafa Digital (FAD) además de un código que será enviado a través de un mensaje SMS al celular que se indica en la tabla de firmantes. En el caso del solicitante, será necesario tener a la mano su identificación oficial (credencial del INE/IFE) para la firma del Contrato de Crédito.
          <a class="bold" @click="goToTutorial()" target="_blank">¿Tienes dudas? Consulta este tutorial sobre cómo firmar un contrato con Firma Autógrafa Digital (FAD) dando clic aquí.</a>
        </p> <br>
        <template v-for="(group, key) in groupedContracts">
          <vx-card v-if="group.length != 0" class="mb-6" :key="key" :title="`Grupo ${group[0].group.name}`" >
            <p><strong>Nota:</strong> {{getDisclaimerLabel(key+1)}}</p>
            <!-- TABLA DE FIRMANTES -->
            <p class="bold primary mt-4">Por favor valida que los datos de correo y celular sean correctos. </p>
            <vs-table class="mt-2 mb-8" :data="handleSignatories(group[0])" noDataText="No hay datos disponibles." stripe>
              <template slot="thead">
                <vs-th class="bold" v-for="(col, idx) in columnsSignatory" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
              </template>
              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].type">
                    {{ getRoles(data[indextr].type) }} 
                  </vs-td>
                  <vs-td :data="data[indextr].name">
                    {{ data[indextr].name != null && data[indextr].name != "" ? data[indextr].name : "Sin información" }}
                  </vs-td>
                  <vs-td :data="data[indextr].email">
                    {{ data[indextr].email != null && data[indextr].email != "" ? data[indextr].email : "Sin información" }}
                  </vs-td>
                  <vs-td :data="data[indextr].telephone">
                    {{ data[indextr].telephone != null && data[indextr].telephone != "" ? phoneFormat(data[indextr].telephone) : "Sin información" }}
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <!-- TABLA DE FIRMANTES -->
            <vs-divider></vs-divider>
            <!-- DETALLE DE CONTRATOS -->
            <p class="bold primary mt-4 ">Detalle de contratos</p>
            <div class="vx-row">
              <div class="vx-col lg:w-1/3 md:w-full sm:w-full" style="padding-left: 3rem;">
                <p class="bold mt-4 mb-2">Documentos</p>
                <ul class="ul-bullet" v-for="(document, index) in group" :key="index">
                  <li>
                    {{document.type.name}}
                  </li>
                </ul>
              </div>
              <div class="vx-col lg:w-1/3 md:w-full sm:w-full"  style="padding-left: 3rem;">
                <p class="bold mt-4 mb-2">Información adicional</p>
                <ul class="ul-bullet">
                  <li>
                    <strong>Creado(s): </strong>{{ regularDateFormat(group[0].created_at)}}
                  </li>
                  <li>
                    <strong>Estado: </strong>{{status(group[0])}}
                  </li>
                  <li>
                    <strong>Tipo de firma: </strong>{{signing_type(group[0].signing_type)}}
                  </li>
                </ul>
              </div>
              <div class="vx-col lg:w-1/3 md:w-full sm:w-full" style="padding-left: 3rem;">
              <p class="bold mt-4 mb-2">Acciones</p>
                <template  v-for="(link, indexLink) in buildFADLinks(group)">
                  <div v-if="group[0].status != 'signed'"  :key="indexLink" class="vx-row">
                    <div class="vx-col w-full">
                      <p class="bold text-sm">{{link.type}}:</p>                
                      <div class="flex items-center">  
                        <vs-button size="small" type="border" icon="open_in_new" class="mb-2 mr-3" @click.stop="handleFadLink(link.ticket)">Abrir</vs-button>
                        <vs-button size="small" type="border" icon="content_copy" class="mb-2 mr-3" @click.stop="copyToClipboard(link.ticket)">Copiar</vs-button>
                      </div>
                    </div>
                  </div>
                </template>
                
                <vs-button v-if="group[0].status == 'signed'" 
                  size="small" type="border" class="mb-2 mr-3" target color="success"
                  @click="handleDownload(group[0].id, group[0].contract_signed_file)">
                  Descargar PDF<br>firmado
                </vs-button>

                <vs-button v-if="group[0].status == 'signed'" 
                  size="small" type="border" class="mb-2" target color="success"
                  @click="handleDownload(group[0].id, group[0].contract_signed_xml_file)">
                  Descargar XML<br>firmado
                </vs-button>
              </div>
            </div>
            <!-- DETALLE DE CONTRATOS -->
          </vx-card>
        </template>
      </div>

      <div v-else>
         <p>RedGirasol esta generando los contratos, en este apartado aparecerán para que sean firmados</p>
      </div>

      <vs-popup
        title="Información adicional"
        :active.sync="popUpInformation">
        <vs-table :data="contractsInfo" stripe>
          <template slot="thead">
            <vs-th class="bold" v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].group" style="min-width: 80px !important">
                {{ data[indextr].group }} 
              </vs-td>
              <vs-td :data="data[indextr].documents">
                {{ data[indextr].documents }}
              </vs-td>
              <vs-td :data="data[indextr].when_to_sign">
                {{ data[indextr].when_to_sign }}
              </vs-td>
              <vs-td :data="data[indextr].required_for">
                {{ data[indextr].required_for }}
              </vs-td>
              <vs-td :data="data[indextr].wish_to_sign">
                {{ data[indextr].wish_to_sign }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-popup>

   </vx-card>
</template>

<script>
import dateHelper from '@components/mixins/dateHelper';
import formatHelper from '@components/mixins/formatHelper';
import {mapState} from "vuex";

const tutorialFADLink = "https://youtu.be/19npgHmTUi0";
const FADLinkBaseDevelopment = "https://uatwebfad.firmaautografa.com/signList?ticketReq=";
const FADLinkBaseProduction = "https://mobile.firmaautografa.com/main?ticket=";

const columnDefinitions = [
  { title: "GRUPO", kye: "group", sortable:false }
  , { title: "DOCUMENTOS", kye: "documents", sortable:false }
  , { title: "DEBE SER FIRMADO", kye: "when_to_sign", sortable:false }
  , { title: "SE REQUIERE LA FIRMA PARA EL PAGO DE", kye: "required_for", sortable:false }
  , { title: "FIRMADO POR", kye: "wish_to_sign", sortable:false }
];

const columnsSignatory = [
  { title: "FIRMANTE", kye: "signatory", sortable:false }
  , { title: "NOMBRE", kye: "name", sortable:false }
  , { title: "CORREO", kye: "email", sortable:false }
  , { title: "CELULAR", kye: "telephone", sortable:false }
];

const contractsInfo = [
  { group: "1", documents: "Contrato de Crédito, Pagaré, Prenda en garantía, Carta de entendimiento, Domiciliación", when_to_sign: "Durante el proceso de fondeo", required_for: "Anticipo", wish_to_sign: "Solicitante, Obligados Solidarios en caso de aplicar y RedGirasol"}
  , { group: "2", documents: "Contrato de Compra venta", when_to_sign: "Durante el proceso de fondeo", required_for: "Anticipo", wish_to_sign: "Proveedor, Solicitante y RedGirasol"}
  , { group: "3", documents: "Anexo 1 del Contrato de Crédito, Anexo 1.2 y Anexo 2 de la Prenda en garantía", when_to_sign: "Durante el proceso de instalación", required_for: "Finiquito", wish_to_sign: "Solicitante, Obligados Solidarios en caso de aplicar y RedGirasol"}
  , { group: "4", documents: "Anexo 1.2 del Contrato de Compra venta", when_to_sign: "Durante el proceso de instalación", required_for: "Finiquito", wish_to_sign: "Proveedor, Solicitante y RedGirasol"}
];

export default {
   name: 'contracts-table',
  props: [ 'id', 'project', 'client', 'isDisabled'],
  mixins: [dateHelper, formatHelper],
  data(){
    return{
      isMounted: false,
      signingMifielWidgetId: '3fa8a920-f0e8-452c-9097-020d356a95d',
      tutorialFADLink: tutorialFADLink
      , popUpInformation: false
      , columns: columnDefinitions
      , contractsInfo: contractsInfo
      , columnsSignatory: columnsSignatory
      , signatorsData: []
      , groupedContracts: []
      , hasOldContracts: false
      , FADLinkBase: process.env.VUE_APP_ENV == "production" ? FADLinkBaseProduction : FADLinkBaseDevelopment
    }
  },
  computed: {
   ...mapState("auth", ["user"]),
    hasContracts(){
      return this.project.contracts.length > 0;
    },
    manual(){
      if(this.project.signing_type_wish != null){
        return this.project.signing_type_wish == 1;
      } else {
        if(this.groupedContracts[0] != undefined && this.groupedContracts[0][0] != undefined){
          return this.groupedContracts[0][0].signing_type == 1;
        }
      }
    },
    electronic(){
      return this.project.signing_type_wish == 2;
    },
    electronicFAD(){
      if(this.project.signing_type_wish != null){
        return this.project.signing_type_wish == 3;
      } else {
        if(this.groupedContracts[0] != undefined && this.groupedContracts[0][0] != undefined){
          return this.groupedContracts[0][0].signing_type == 3;
        }
      }
    },
    accessToken(){
      return this.access_token;
    },
    label(){
      return "Contratos - " + this.project.alias;
    },
    userEmail(){
       return this.user.email;
    }
  },
  async mounted(){
    this.groupContracts();
  },
  methods:{
    signatories(contract){
      if (contract.signatories_json != null){
         let ss = "";
         let names = [];
         contract.signatories_json.forEach(item => {
            names.push(item.email);
         });   
         return names;
      }
      else {
        return "--";
      }      
    },
    signatoriesContainsSupplier(contract){
      if (contract.signatories_json != null){
         return contract.signatories_json.find(f => f.email == this.userEmail) != null;
      }
      else {
        return false;
      }      
    },
    status (contract){
      if(contract.status == "generated"){
         return "Generado";
      }
      else if(contract.status == "sent"){
         return "Enviado";
      }
      else if(contract.status == "signed"){
         return "Firmado";
      }
    },
    signRequest(contract){
      //let wid = this.signingMifielWidgetId;
      const mifielObjData = contract.mifiel_object;
      const mifielObj = JSON.parse(mifielObjData);
      let wid = mifielObj.widget_id;
      this.$router.push({name: 'mifielContractSign', params:{id: this.id, wid: wid}});
    },
    async getSignatorsInformation(){
      try{
        let response = await axios.get(`/api/test/${this.project.id}`);
        if(response.data != ""){
          this.signatorsData = response.data;
        } else {
          this.errorNotif("Algo ha salido mal.", "No hemos podido obtener información sobre los firmantes de los contratos, intenta recrgando la página o comunicate con soporte técnico");
        }
      }catch(e){
        // console.log(e);
      }
    },
    groupContracts(){
      let g1 = [];
      let g2 = [];
      let g3 = [];
      let g4 = [];
      let generic = [];
      this.project.contracts.forEach( contract => {
        switch(contract.contract_group_id){
          case 1: g1.push(contract); break;
          case 2: g2.push(contract); break;
          case 3: g3.push(contract); break;
          case 4: g4.push(contract); break;
          default: 
            generic.push(contract); 
            this.hasOldContracts = true;
        }
      });
      this.groupedContracts = [g1, g2, g3, g4, generic];
      // console.log(this.groupedContracts);
    },
    getRoles(role){
      let r = "";
      switch(role){
        case "customer":
          r = "Solicitante";
        break;
        case "supplier":
          r = "Proveedor";
        break;
        case "redgirasol":
          r = "RedGirasol";
        break;
        default: r = "Obligado Solidario";
      }

      return r;
    },
    signing_type(wish){
      let r = "";
      switch(wish){
        case 1:
          r = "Manual Autógrafa";
        break;
        case 2:
          r = "Electrónico";
        break;
        case 3:
          r = "Firma Autógrafa Digital (FAD)";
        break;
        default: r = "--";
      }

      return r;
    },
    buildFADLinks(contractsGroup){
      let groupId = contractsGroup[0].group.id;
      if (contractsGroup[0].fad_requisition != null){
        let requisition = JSON.parse(contractsGroup[0].fad_requisition);
        let links = [];
  
        let index = 0;
        let allTickets = Object.values(requisition.allTickets);
  
        allTickets.forEach(ticket => {
          let link = {};
          if(index == 0){
            link.type =  "Firma Solicitante";
          } else {
            let name = "";
            if(groupId == 1){
              name = "Firma Obligado Solidario";
            } else if (groupId == 2 || groupId == 4){
              name = "Firma Proveedor";
            } else {
              name = "N/A";
            }
            link.type = name;
          }
          link.ticket = ticket;
          links.push(link);
          index++;
        });
        return links;
      } else {
        return [];
      }
    },
    getDisclaimerLabel(group){
      let label = "Se requiere la firma de este grupo para el pago de << ANTICIPO >>.";
      if(group == 4 || group == 3){
        label = "se requiere la firma de este grupo para el pago de << FINIQUITO >>.";
      } else if(group == 5) {
        label = "";
      }
      return label;
    },
    copyToClipboard(ticket){
      const el = document.createElement('textarea');
      el.value = this.FADLinkBase + ticket;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.successNotif("Liga copiada al portapapeles");
    },
    handleFadLink(ticket){
      window.open(this.FADLinkBase + ticket, '_blank');
    },
    handleSignatories(data){
      let arraySignatories = JSON.parse(data.signatories);
      if (arraySignatories != null){  
         return arraySignatories;
      }
      else {
        return [];
      }   
    },
    goToTutorial(){
      try{
        window.open(this.tutorialFADLink,"_blank");
      } catch (err) {
        console.error("Algo salió mal con el link del tutorial.", err);
        this.errorNotif();
      }
    },
    handleDownload(contract_id, contract_type){
      try {
        let url = `${this.ApiDomain}/storage/private/contracts/${contract_id}/${contract_type}?access_token=${this.access_token}`;
        window.open(url,"_blank");
      } catch (error) {
        console.error("Algo salió mal con el link del tutorial.", err);
        this.errorNotif();
      }
    }
  }
}
</script>

<style lang="css" scoped>
  .created {
    font-size: 12px !important;
    font-weight: 900 !important;
  }

  td ul {
    list-style-type: inherit !important;
  }

  .align-btn {
    text-align: right !important;
  }

  .ul-bullet {
    list-style-type: inherit !important;
  }

  .info-border-inline{
    border: 1px solid rgba(var(--vs-rgblack),1);
    background: transparent!important;
    color: rgba(var(--vs-rgblack),1);
    padding: .68rem 0.75rem;
    border-radius: 6px;
  }

  .colored-info-border-inline {
      border: 2px solid rgba(var(--vs-primary),1);
      background: transparent!important;
      color: rgba(var(--vs-primary),1);
      border-radius: 10px;
  }

  .colored-info-btn-inline {
      background-color: rgba(var(--vs-primary),1);
      border-radius: 0 6px 6px 0;
  }
</style>