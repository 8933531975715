<template>
  <div class="vx-col w-full mb-5" >
    <h6 class="mb-6">{{f.indication}}</h6>

    <template v-if="!hasBackofficeAccess">
      <vs-alert v-if="pendingVerificationRequest && pfg_locations.length > 0" icon-pack="feather" icon="icon-alert-triangle" class="mt-4 mb-base" color="dark">
          <span class="font-regular">La sección se encuentra en proceso de ser completada.
          Cuando consideres la información y documentos cargados son los adecuados en todas las ubicaciones, da click en el botón <strong>"Solicitar validación de evidencias"</strong> para enviarla a revisión.</span>
      </vs-alert>

      <div v-if="pendingVerificationRequest && pfg_locations.length > 0" class="vx-col w-full mt-4 mb-base flex" id="account-manage-buttons">
        <vs-button class="mr-4" @click="requestAccreditation">Solicitar validación de evidencias</vs-button>
      </div>

      <vs-alert v-if="!pendingVerificationRequest && !evidencesVerified" icon-pack="feather" icon="icon-alert-circle" style="height:auto" class="mt-4 mb-base" color="warning">
        <span class="font-regular">Se ha solicitado la revisión de las evidencias. En breve se te dará respuesta.</span>
      </vs-alert>

      <vs-alert v-if="evidencesVerified" icon-pack="feather" icon="icon-check-circle" style="height:auto" class="mt-4 mb-base" color="success">
        <span class="font-regular">Las evidencias ha sido verificadas.</span>
      </vs-alert>
    </template>

    <template v-else>
      <vs-alert v-if="pendingVerificationRequest && pfg_locations.length > 0" icon-pack="feather" icon="icon-alert-triangle" class="mt-4 mb-base" color="dark">
        <span class="font-regular">La sección se encuentra en proceso de ser completada.</span>
      </vs-alert>
      <vs-alert v-if="!pendingVerificationRequest && !evidencesVerified" icon-pack="feather" icon="icon-alert-circle" style="height:auto" class="mt-4 mb-base" color="warning">
        <span class="font-regular">Se ha solicitado la revisión de las evidencias.</span>
      </vs-alert>
      <vs-alert v-if="evidencesVerified" icon-pack="feather" icon="icon-check-circle" style="height:auto" class="mt-4 mb-base" color="success">
        <span class="font-regular">Las evidencias ha sido verificadas.</span>
      </vs-alert>
    </template>

    <div v-if="show_resend_survey_button" class="vx-col w-full mt-4 mb-base flex" id="account-manage-buttons">
      <vs-button class="mr-4" @click="sendSurveyEmails()">Reenviar encuesta de servicio al cliente</vs-button>
    </div>

    <vs-table stripe :data="pfg_locations" noDataText="No se han generado registros">
      <template slot="thead">
        <vs-th class="bold">UBICACIÓN</vs-th>
        <vs-th class="bold">SISTEMA O TECNOLOGÍA</vs-th>
        <vs-th class="bold">ACCIONES</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">

          <vs-td :data="data[indextr].id">
            {{ addressForLocation(data[indextr]) }}
          </vs-td>

          <vs-td :data="data[indextr].family_group_id">
            {{ uppercase(data[indextr].family_group.name) }}
          </vs-td>

          <vs-td :data="data[indextr].id">
            <feather-icon v-if="!hasBackofficeAccess && !isDisabled" icon="EditIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" @click.stop="showItemRequest(data[indextr])" />
            <feather-icon v-if="hasBackofficeAccess || isDisabled" icon="EyeIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer" @click.stop="showItemRequest(data[indextr])" />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

  </div>

</template>

<script>
import inputHelper from "@mixins/inputHelper"

export default {
  name: 'technology-location-evidences-control',
  mixins: [inputHelper],
  props: {
    project_id: {type: [String, Number], required: true},
    f: { type: Object, required: true },
    isDisabled: { type: [String,Boolean], default: false },
  },
  data(){
    return {
      pfg_locations: [],

      showFormPrompt: false,
      selectedTechnology: null,
      project: {},
    }
  },
  beforeMount: async function(){
    await this.loadSavedProjectFgLocations();
  },
  computed: {
    pendingVerificationRequest(){
      return this.project.evidence_verification_request_date == null
    },
    evidencesVerified(){
      return this.project.complete_evidence_date != null
    },
    hasBackofficeAccess(){
      return this.$acl.check('isBackoffice');
    },
    show_resend_survey_button()
    {
      return this.project.applicant_feedback_date == null && this.project.applicant_feedback_request_date != null;
    }
  },
  methods: {
    addressForLocation(pfg_location){
      if(pfg_location.location && pfg_location.location.address){
        let addr = pfg_location.location.address.full_address;
        if(addr.length > 60){
          return addr.substring(0, 60) + "...";
        }
        else {
          return addr;
        }
      }
      else{
        return "Sin definir";
      }
    },
    async loadSavedProjectFgLocations(){
      try {
        let res = await axios.get(`/api/v1/project/${this.project_id}/getProjectFgLocations`);
        this.pfg_locations = res.data;

        let res2 = await axios.get(`/api/v2/projects/${this.project_id}/`);
        this.project = res2.data;
      }
      catch (e) {
        this.warn(e);
      }
    },
    showItemRequest(pfg_location){
      this.$router.push({ name: 'evidenciasFgLocationProyecto', params: { id: this.project_id, fg_id: pfg_location.id } })
    },

    async requestAccreditation(){
      let ok  = await this.checkForValidAccreditation();

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Solicitar acreditación de tecnología',
        text: `¿Estás seguro que deseas enviar tu solicitud a revisión? La solicitud será atendida en breve.`,
        acceptText: "Enviar solicitud",
        cancelText: 'Cancelar',
        accept: this.doRequestAccreditation
      });
    },

    async doRequestAccreditation(){
      this.showLoading(true);
      try {
        await axios.post(`/api/v1/project/${this.project_id}/requestProjectEvidencesVerification`, {});
        await this.loadSavedProjectFgLocations();
        this.warningNotif(
          "Encuesta de satisfacción"
          , "Se ha enviado un correo al Solicitante para el llenado de la encuesta de servicio, de la cual es necesaria su respuesta para poder realizar el pago de finiquito."
          , 15000
        );

      } catch (error) {
        this.warn(error);
        this.failedOperationNotif(null, null);
      }
      this.showLoading(false);
    },
    async checkForValidAccreditation(){
      // comprobar que se cumplan todos los requerimientos
      return false;
    },

    /* check validation */
    validate(){
      return this.technologies.length >= 3;
    },
    async sendSurveyEmails(){
      try {
        let payload = [{ "pids" : [this.project_id]}];
        await this.publicApiPost(`/api/v1/applicant_satisfaction_survey/sendSurveyEmails`, payload);
        this.warningNotif(
          "Encuesta de satisfacción"
          , "Se ha enviado un correo al Solicitante para el llenado de la encuesta de servicio, de la cual es necesaria su respuesta para poder realizar el pago de finiquito."
          , 15000
        );
      } catch (error) {
        this.warn(error);
        this.failedOperationNotif(null, null);
      }
    }
  }
}
</script>