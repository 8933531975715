<template>
  <div class="vx-row px-4">
    <div class="vx-col w-full">
      <div class="text-start">
        <h5 class="bold mb-3">Avance de fondeo</h5>
        <p class="pf-3">{{progressPrcg}}</p>
      </div>
    </div>
    <div class="vx-col w-full">
      <div slot="no-body">
        <div class="mb-4 mt-5">
          <div class="flex justify-between">
            <span class="font-semibold text-xl">{{ progressAmount }}</span>
            <span class="text-grey text-xl">{{ goalAmount }}</span>
          </div>
          <vs-progress :percent="percentage" class="block mt-2 shadow-md"></vs-progress>
        </div>
        
        <!-- <div class="flex flex-wrap px-4 mt-6">
          <span class="mr-4">
              <p class="text">{{ investors }} <span class="text-grey text-sm"> Total de inversionistas</span></p>
          </span>
          <span>
              <p class="text text-right">{{ daysFormatted }} <span class="text-grey text-sm"> Días restantes</span></p>
          </span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'project-fund-progress',
  props: ['total', 'invested', 'remaining_days', 'investors' ],
  methods: {
    moneyFormat(value) {
      if(!value) return "$0";
      else return "$ " + this.format_price(value, 0);
    },
    format_price(value, decimales = 0) {
      let val = (value / 1).toFixed(decimales).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    prcgFormat(value, decimales = 0){
      if(!value) return "0%";
      else return value.toFixed(decimales) + "%";
    },
  },
  computed: {
    progressAmount(){
      return this.moneyFormat(this.invested);
    },
    goalAmount(){
      return this.moneyFormat(this.total);
    },
    percentage(){
      return (this.invested / this.total) * 100;
    },
    progressPrcg(){
      return this.prcgFormat(this.percentage, 0);
    },
    daysFormatted(){
      if(this.remaining_days < 0){
        let r = this.remaining_days * -1;
        return `0 + ${r} de más`
      }
      else {
        return this.remaining_days;
      }
    }
  }
}
</script>

<style>
.pf-3{
  font-size: 1.5rem !important;
}
</style>