<template>
  <div class="vx-col w-full mb-5" >
    <h6 class="mb-4">{{f.indication}}</h6>
    <vs-button v-if="!isDisabled && !hasBackofficeAccess" color="primary" type="border" icon-pack="feather" class="mb-4 mt-4"  @click="addTechnology()">Agregar tecnología</vs-button>
        
      <vs-table stripe :data="pfg_locations" noDataText="No se han generado registros">
        <template slot="thead">
          <vs-th class="bold">UBICACIÓN</vs-th>
          <vs-th class="bold">SISTEMA O TECNOLOGÍA</vs-th>
          <vs-th class="bold">ESTADO</vs-th>
          <vs-th class="bold">ACCIONES</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="data[indextr].id" class="table-wrap">
              {{ addressForLocation(data[indextr]) }}
            </vs-td>

            <vs-td :data="data[indextr].family_group_id" class="table-wrap">
              {{ uppercase(data[indextr].family_group.name) }}
            </vs-td>

            <vs-td :data="data[indextr].id" class="table-wrap">
              <span :class="getTechnologyStatusClass(data[indextr].id)">{{ getTechnologyStatus(data[indextr].id) }}</span>
            </vs-td>

            <vs-td :data="data[indextr].id">
              <feather-icon
                v-if="!hasBackofficeAccess && !isDisabled"
                icon="EditIcon"
                svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer"
                @click.stop="editItemRequest(data[indextr])" />

              <feather-icon
                v-if="!hasBackofficeAccess && !isDisabled"
                icon="TrashIcon"
                svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer"
                class="ml-6"
                @click.stop="removeItemRequest(data[indextr])" />

              <feather-icon
                v-if="hasBackofficeAccess || isDisabled"
                icon="EyeIcon"
                svgClasses="w-5 h-5 hover:text-danger stroke-current cursor-pointer"
                @click.stop="editItemRequest(data[indextr])" />
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <vs-popup
        title="Selección de tecnología o sistema para la ubicación"
        :active.sync="showFormPrompt">
        <div class="con-exemple-prompt">
          <vs-alert v-if="onError" class="h-full mb-5" color="danger">
            <span>{{ errorMssg }}</span>
          </vs-alert>
          <vs-alert v-if="onSuccess" class="h-full mb-5" color="success">
            <span>{{ successMssg }}</span>
          </vs-alert>

          <p class="mb-5 bold">{{formPromptIndication}}</p>

          <label for="" class="vs-input--label">Sistema o tecnología deseada</label>
          <v-select
            name="family_group_id"
            label="text"
            class="vs-custom w-full mb-5"
            v-model.lazy="selected_family_group_id"
            :options="getGroupsForSelect"
            :reduce="text => text.value"
            :clearable="false"
            :searchable="false"
            placeholder="Seleccione una opción" >
            <div slot="no-options">No hay coincidencias</div> 
          </v-select>
          <v-error v-if="hasError('selected_family_group_id')" :error="errorText('selected_family_group_id')" :success="isSuccess('selected_family_group_id')"/>

          <vs-button :disabled="!completeForm || $isLoading" @click="addPfgLocation" color="primary" class="mr-5 mt-2">Agregar</vs-button>
          <vs-button @click="closeFormPrompt" :disabled="$isLoading" type="flat" color="primary">Cerrar</vs-button>

        </div>
      </vs-popup>

    </div>

</template>

<script>
import inputHelper from "@mixins/inputHelper"
const indicationDef = "Selecciona qué tecnología deseas integrar.";
const errorMssgDef = "Ha ocurrido un error, intente más tarde."
const successMssgDef = "El registro ha sido agregado exitosamente"

export default {
  name: 'technology-selector-control',
  mixins: [inputHelper],
  props: {
    project_id: {type: [String, Number], required: true},
    f: { type: Object, required: true },
    isDisabled: { type: [String,Boolean], default: false },
  },
  data(){
    return {    
      getGroupsForSelect: [],
      pfg_locations: [],
      selected_family_group_id: null,

      showFormPrompt: false,
      selectedTechnology: null,

      onError: false,
      onSuccess: false,
      errorMssg: errorMssgDef,
      successMssg: successMssgDef,
      formPromptIndication: indicationDef,

      technologyValidations: null,
    }
  },
  mounted: async function(){
    if(!this.hasBackofficeAccess){
      await this.getSupplierFamilyGroups();
    }
    await this.loadSavedProjectFgLocations();
  },
  computed: {
    completeForm(){
      return this.selected_family_group_id != null;
    },
    hasBackofficeAccess(){
      return this.$acl.check('isBackoffice');
    },
  },
  methods: {
    addressForLocation(pfg_location){
      if(pfg_location.location && pfg_location.location.address){
        let addr = pfg_location.location.address.full_address;
        if(addr.length > 60){
          return addr.substring(0, 60) + "...";
        }
        else {
          return addr;
        }
      }
      else{
        return "Sin definir";
      }      
    },
    async getSupplierFamilyGroups(){
      try {
        let res = await axios.get(`/api/v1/supplier/${this.SupplierId}?with[]=supplierFamilyGroups.familyGroup`);
        let groups = res.data.supplier_family_groups;

        let options = [];
        groups.forEach(opt => {
          if(opt.accreditation_status == 2){
            let fam = opt.family_group;
            if(fam.is_available == 1) {
              options.push({value: fam.id, text: fam.name}); //conversion a sintaxis de vx-select 
            }
          }
        })
        this.getGroupsForSelect = options;
        
      } catch (error) {
        
      }
    },
    async loadSavedProjectFgLocations(){
      try {
        const res = await axios.get(`/api/v1/project/${this.project_id}/getProjectFgLocations`);
        this.pfg_locations = res.data;

        const resValidations = await axios.get(`/api/v1/project/${this.project_id}/verifyProjectTechnologies`);
        this.technologyValidations = resValidations.data;
      }
      catch (e) {
        this.warn(e);
      }
    },
    removeItemRequest(technology){
      this.selectedTechnology = technology;

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Descartar registro',
        text: `¿Realmente desea eliminar el registro? Todos los datos registrados para esta tecnología también serán descartados`,
        acceptText: "Eliminar",
        cancelText: 'Cancelar',
        accept: this.doRemoveItem
      });
    },
    editItemRequest(pfg_location){
      this.$router.push({ name: 'editarFgLocationProyecto', params: { id: this.project_id, fg_id: pfg_location.id } })
    },
    async doRemoveItem(){
      this.showLoading(true);
      try {
        let payload = {pfg_location_id: this.selectedTechnology.id};
        await axios.post('/api/v1/supplier/removeTechnologyFromProject', payload);
        this.successNotif("Operación exitosa", "Registro descartado correctamente");
        await this.loadSavedProjectFgLocations();
      }
      catch (error) {
        this.warn(error);
      }
      this.showLoading(false);
    },
    async addPfgLocation(){
      this.onSuccess = false;
      this.onError = false;
      
      this.showLoading(true);
      try {
        let fam_id = this.selected_family_group_id;
        let payload = { family_group_id: fam_id, project_id: this.project_id};
        let res = await axios.post(`/api/v1/project/${this.project_id}/addFgLocationToProject`, payload);
        let fg_location_id;

        if(res.data.success != true){
          this.errorMssg = res.data.error;
          this.onError = true;
        }
        else {
          fg_location_id = res.data.id;
          this.onSuccess = true;
          this.closeFormPrompt();
  
          // redireccionar a la vista de edicion
          this.$router.push({ name: 'editarFgLocationProyecto', params: { id: this.project_id, fg_id: fg_location_id } })
        }
      }
      catch (error) {
        this.warn(error);
        this.onError = true;
      }
      this.showLoading(false);
    },
    addTechnology(){
      this.resetForm();
      this.showFormPrompt = true;
    },
    resetForm(){
      this.selected_family_group_id = null
      this.onError = null
      this.onSuccess = null      
      this.sent = null
    },
    closeFormPrompt() {
      this.showFormPrompt = false;
      this.resetForm();
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);
    },

    getTechnologyStatus(id){
      if(!this.technologyValidations){
        return null;
      }
      const v = this.technologyValidations.validations.find(f => f.project_fg_location_id === id);
      if(v.dataValidations.complete && v.docValidations.complete){
        return "Info. y documentos completos";
      }
      else if(!v.dataValidations.complete && !v.docValidations.complete) {
        return "Info. y documentos pendientes"
      }
      else if(!v.dataValidations.complete) {
        if(!v.dataValidations.pgRequestsComplete && v.dataValidations.locationComplete && v.dataValidations.workplanComplete && v.dataValidations.fgReqsComplete){
          return "Datos de modelos incompletos";
        }
        else {
          return "Información incompleta";
        }
      }
      else if(!v.docValidations.complete) {
        return "Documentos pendientes";
      }
      else {
        return "Desconocido";
      }
    },

    getTechnologyStatusClass(id){
      if(!this.technologyValidations){
        return "";
      }

      const v = this.technologyValidations.validations.find(f => f.project_fg_location_id === id);
      if(v.dataValidations.complete && v.docValidations.complete){
        return "text-success";
      }
      else {
        return "text-danger";
      }
    },

    /* check validation */
    validate(){
      return this.pfg_locations.length >= 1;
    }
  }
}
</script>