<template>
  <div>
    <!-- SECCIONES -->
      <vs-tabs v-if="isMounted" :position="isSmallerScreen ? 'top' : 'left'" v-model="activeTab" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
        <vs-tab :label="group.title" icon-pack="feather" :icon="group.icon" v-for="(group, i) in sectionGroups" :key="i">
          <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
            <vx-card no-shadow >
              <vx-card v-for="section in group.sections" :key="section.data.id" :title="section.data.public_name" class="mb-5">
                <p class="mb-4" v-if="section.data.public_description != null">{{section.data.public_description}}</p>

                <div class="vx-row">
                  <template v-for="f in section.data.fields">

                    <!-- Campos automaticos -->
                    <form-field 
                      :ref="group.slug"
                      v-if="isAutoFormField(f)"
                      :key="f.id"
                      :f="f"
                      :dataContainer="getContainerForField(section, f)"
                      :collectionsRoot="collections"
                      :onChangeReceptor="onFieldChange"
                      :keepDisabled="isDisabled"
                      :hasBackofficeAccess="hasBackofficeAccess"
                      :inputClassLarge="true"
                      :evaluator="evaluateCondition" />

                     <technology-location-evidences-control
                      :ref="group.slug"
                      v-else-if="f.fname == 'technology_evidences_selector'"
                      :project_id="id"
                      :key="f.id"
                      :f="f"
                      :isDisabled="isDisabled"
                     />

                     <technology-location-technical-control
                      :ref="group.slug"
                      v-else-if="f.fname == 'technology_technical_selector'"
                      :project_id="id"
                      :key="f.id"
                      :f="f"
                      :isDisabled="isDisabled"
                     />

                     <invoices-control
                     ref="invoices"
                     :key="f.id"
                     :project_id="id"
                     v-else-if="f.fname == 'project_invoices'"
                     :isDisabled="isDisabled"
                     />

                    <impacta-mas-invoices-control
                      ref="impactamas_invoices"
                      :key="f.id"
                      :project_id="id"
                      :s173_amount="supplierBonusAmount"
                      :s176_amount="supplierBonusIvaAmount"
                      :total="supplierBonusTotalAmount"
                      v-else-if="f.fname == 'bonus_project_invoices'"
                      :isDisabled="isDisabled"
                    />

                     <complementary-images
                     ref="complementary_images"
                     :key="f.id"
                     :project_id="id"
                     v-else-if="f.fname == 'project_complementary_images'"
                     :isDisabled="isDisabled"
                     />

                  </template>
                </div>

                  <!-- Save Button -->
                <div class="vx-row" v-if="!isDisabled && !section.data.skipBtn">
                  <div class="vx-col w-full">
                    <div class="flex flex-wrap items-center justify-end">
                      <vs-button class="ml-auto mt-2" @click="saveTabChanges(group.slug)">Guardar cambios</vs-button>
                    </div>
                  </div>
                </div>

                <template v-if="section.data.documentFields.length > 0">
                  <vs-divider></vs-divider>
                  <h6 class="mb-3">Documentos</h6>
                  <div class="vx-row">
                    <document-upload
                    v-for="df in section.data.documentFields"
                    :key="df.id"
                    :target_id="df.target_id"
                    :f="df.formField"
                    :doc="df.documentSet"
                    :inputClassLarge="true"
                    :disabled="isDisabled"
                    :evaluator="evaluateCondition"
                    />
                  </div>
                </template>
              </vx-card>

              <vx-card v-if="group.slug == 'payments' && !isMaster" class="mb-5" title="Pagos y comisiones">
                  <project-payments-invoices :project_id="id" @on-payment-selected="onHandlePaymentSelected" />
              </vx-card>

              <template v-else-if="group.slug == 'payments' && isMaster" >
                <vx-card class="mb-5" v-for="split in base.splits" :key="split.id" :title="'Pagos y comisiones ' + split.alias">
                  <project-payments-invoices :project_id="split.id" @on-payment-selected="onHandlePaymentSelected" />
                </vx-card>
              </template>

              <!-- <div v-if="group.slug == 'contracts'" class="mb-5">
                <contracts-control ref="contracts" :project_id="id" :isDisabled="isDisabled" />
              </div> -->

            </vx-card>
          </div>
        </vs-tab>

      </vs-tabs>

  <load-error v-if="failed" />
  <project-payments-detail-popup :data="sidebarData" />

  </div>
</template>

<script>
import { mapState } from 'vuex';
import inputHelper from '@components/mixins/inputHelper';
import dateHelper from '@components/mixins/dateHelper';
import formHelper from '@components/mixins/formHelper';
import FormField from '@components/forms/FormField.vue'
import TechnologyLocationEvidencesControl from '@technologies/TechnologyLocationEvidencesControl.vue'
import TechnologyLocationTechnicalControl from '@technologies/TechnologyLocationTechnicalControl.vue'
import ComplementaryImages from '@projects/ComplementaryImages.vue'
import ProjectPaymentsDetailPopup from '../projects/ProjectPaymentsDetailPopup';
import ProjectPaymentsInvoices from '@projects/ProjectPaymentsInvoices.vue'
import InvoicesControl from '@projects/InvoicesControl.vue'
import ContractsControl from '@projects/ContractsControl.vue'
import ImpactaMasInvoicesControl from "@projects/ImpactaMasInvoicesControl";

const tabsDefs = [
  { slug: "invoices", title: "Facturas del proyecto", icon: "icon-paperclip", sections:[46] },
  { slug: "bonus_invoices", title: "Facturas bono ImpactaMás", icon: "icon-award", sections:[53] },
  { slug: "technical", title: "Datos técnicos por tecnología", icon: "icon-cpu", sections:[45] },
  // { slug: "contracts", title: "Contratos", icon: "icon-edit-2", sections:[] },
  { slug: "evidences", title: "Evidencias por tecnología", icon: "icon-map", sections:[56] },
  { slug: "images", title: "Imágenes complementarias", icon: "icon-image", sections:[43] },
  { slug: "payments", title: "Pagos y comisiones", icon: "icon-file", sections:[] },
];
const sectionsToContainers = [
  { id: 42, containers: []},
  { id: 43, containers: []},
  { id: 45, containers: [] },
  { id: 46, containers: []},
  { id: 53, containers: []},
  { id: 56, containers: []},
];
const requiredObjects = [
  'finance',
  'splits',
];
const genderDefs = [
  {k: 1, v: "HOMBRE"},
  {k: 2, v: "MUJER"},
];

export default {
  mixins: [formHelper, inputHelper, dateHelper],
  props: ['id'],
  data(){
    return {
      errorCode: 0,
      onError: false,
      metaData: {},
      project: {},

      pfTypeValue: 0,
      pfaeTypeValue: 2,
      pmTypeValue: 1,

      flow_id_pf: 3,
      flow_id_pfae: 4,
      flow_id_pm: 5,

      loading: false,
      isMounted: false,
      errorMssg: '',
      successMssg: '',
      failed: false,

      flow: {},
      allSections: [],
      sectionGroups: [],
      documents: [],

      isMaster: null,

      tabs: [],
      payments: {},
      evidences: {},
      downpayment: {},
      contracts: {},

      supplierType: null,
      isMoral: false,
      isPfae: false,
      isPf: false,
      activeTab: 0,

      // objeto principal
      base: {},

      collections: {
        cities: [],
        fiscal_cities: [],
        states: [],
        countries: [],
        family_groups: [],
        tools: [],
        genders: genderDefs
      },

      sidebarData: {},
      supplierBonus: null,
      supplierBonusAmount: 0,
      supplierBonusIvaAmount: 0,
      supplierBonusTotalAmount: 0,
    }
  },
  components: {
    FormField,
    TechnologyLocationEvidencesControl,
    TechnologyLocationTechnicalControl,
    ComplementaryImages,
    ProjectPaymentsInvoices,
    InvoicesControl,
    ContractsControl,
    ProjectPaymentsDetailPopup,
    ImpactaMasInvoicesControl
  },
  computed: {
    ...mapState("auth", ["user"]),
    userId: function() {
      return this.user.user_id;
    },
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    },
    hasBackofficeAccess(){
      return this.$acl.check('isBackoffice');
    },
    project_exists(){
      return true;
    },
    project_id(){
      return this.id;
    },
    isDisabled(){
      return this.base.status == 4 || this.base.status == 8;
    },
    hasSupplierBonus(){
      return this.supplierBonus !== null;
    }
  },
  async created() {
    this.loading = true;
    this.isMounted = false;
    await this.getCollections();
    await this.getProjectData();
    await this.getSupplierBonusInfo();
  //  await this.getGeneralInfo();
    this.setPersonTypeInfo();
    await this.getFormInfo();
    this.setSectionGroups();
    this.setTabFromParams();
    this.isMounted = true;
    this.loading = false;
  },
  watch: {
    loading : function(newVal, oldVal){
      if(newVal == true){
         this.$vs.loading();
      }
      else {
        this.$vs.loading.close();
      }
    },
  },
  methods: {
    setTabFromParams(){
      let tab = this.$route.query.tab;
      if(tab && tab == "evidencias"){
        let index = this.tabs.findIndex(f => f.slug == "evidences");
        this.activeTab = index;
      }
      else if(tab && tab == "tecnicos"){
        let index = this.tabs.findIndex(f => f.slug == "technical");
        this.activeTab = index;
      }
      // else if(tab && tab == "contratos"){
      //   let index = this.tabs.findIndex(f => f.slug == "contracts");
      //   this.activeTab = index;
      // }
    },
    async saveTabChanges(gslug){
      let result = await this.runValidations(gslug);
      if(!result){
       this.missingFieldsNotif();
      }
      else {
        this.loading = true;
        let res = false;

        this.loading = false;
        if(res == true){
          this.saveSuccessNotif();
        }
        else {
          this.failedOperationNotif({text: this.errorMssg});
        }
      }
    },
    async runValidations(gslug){
       // validar componentes tipo FormField
      let res1 = await this.validateFormFields(gslug);
      if(!res1){
        return false;
      }

      // validar inputs instanciados en la vista
      let res2 = await this.$validator.validateAll();
      if(!res2){
        return false;
      }

      // inspeccionar los campos para ejecutar validaciones especiales
      let group = this.sectionGroups.find(g => g.slug == gslug);
      let sp_failed = false;
      group.sections.forEach(s => {
        s.fields.forEach(f => {
          // considerar los campos habilitados (visibles), con validaciones especiales
          if(this.fields[f.fname] && f.special_validation != null) {
            let res = this[f.special_validation.method](group.vModel[f.fname]);

            if(!res){
              this.errors.add({
                field: f.fname,
                msg: f.special_validation.error
              });
              this.missingFieldsNotif(f.field_name, f.special_validation.error, 10000);
              sp_failed = true;
            }
          }
        });
      });

      if(sp_failed){
        return false;
      }

      // retornar true si todas las validaciones fueron positivas
      return true;
    },
    async validateFormFields(gslug){
      let allValid = true;
      let grefs = this.$refs[gslug];
      for(let p in grefs){
        let r = await this.$refs[gslug][p].validate();
        if(r == false){
          allValid = false;
        }
      }
      return allValid;
    },
    onFieldChange(method){
      if(method != null && method in this){
        this[method]();
      }
    },
    evaluateCondition(condition){
     return this.evaluateConditionBase(this, condition);
    },
    /* obtener ids para los casos correspondientes */
    docTargetEvaluator(cls, cls_2){
      if(cls == 'project'){
        return this.id;
      }
      this.warn(`DOC TARGET NOT DEFINED FOR classifications: ${cls},${cls_2}`);
      return null;
    },

    setPersonTypeInfo(){
      this.isMoral = this.metaData.client_is_business;
      this.isPf = !this.metaData.client_is_business;

      // definir si debe aparecer la seccion de facturas ImpactaMas
      if(this.hasSupplierBonus){
        this.tabs = tabsDefs;
      }
      else {
        this.tabs = tabsDefs.filter(f => f.slug !== "bonus_invoices");
      }
    },
    setSectionGroups(){
      this.tabs.forEach(t => {
        let group = { title: t.title, icon: t.icon, slug: t.slug }; //, vModel: this.getModelForGroup(t.slug) };
        let sections = [];

        t.sections.forEach(ss => {
          let s = this.allSections.find(f => f.id == ss);
          if(s != null){
            let sb = { data: s, containers: this.getContainersForSection(s.id) };
            sections.push(sb);
          }
          else {
            this.warn("Section " + ss + " not found");
          }
        })
        group.sections = sections;
        this.sectionGroups.push(group);
      });
    },
    getModelForGroup(slug){
      return this[slug];
    },
    getContainerForField(section, f){
      let c = section.containers.find(sc => sc.db == f.db_table);
      if(!c){
        this.warn(`Container not found for db [${f.fname}]: ${f.db_table}`);
        return {};
      }
      else {
        return c.container;
      }
    },
    getContainersForSection(section_id){
      let c = sectionsToContainers.find(f => f.id == section_id);
      if(!c){
        this.warn("Missing containers definition for section " + section_id);
        return null;
      }
      else {
        let sectionContainers = [];
        c.containers.forEach(cc => {
          let con = this.setContainerFromDef(cc);
          sectionContainers.push({db: cc.db, path: cc.path, container: con});
        })
        return sectionContainers;
      }
    },
    setContainerFromDef(c){
      let container = this.nestedFieldValue(this.base, c.path);
      if(Array.isArray(container)){
        let ac = null;
        if(c.arrayDefs){
          if(c.arrayDefs.getFirst == true){
            ac = container[0];
          }
          else if(c.arrayDefs.eval){
            // llamado en funciones de secciones
            ac = this[c.arrayDefs.eval](container);
          }
        }
        if(!ac){
          if(c.arrayDefs.onNull){ ac = this[c.arrayDefs.onNull](); }
          else { ac = {} }
          container.push(ac);
        }
        return ac;
      }
      else {
        if(!container && c.objDefs && c.objDefs.onNull){
          if(c.objDefs.onNull in this) {
              container = this[c.objDefs.onNull]();
            }
            else {
              this.warn("Missing method definition for container " + c.path + ", " + c.db + ": " + c.objDefs.onNull );
            }
        }
        if(!container){
          console.log("not foiund => " + c.path);
          container = {};
        }
        return container;
      }
    },
    async getSupplierBonusInfo(){
      try{
        const response = await axios.get(`api/impactamas/verify/bonus/p/${this.id}`);
        this.supplierBonus = response.data.s173;
        if(response.data.s173 != null){
          this.supplierBonusAmount = response.data.s173.amount;
        }
        if(response.data.s176 != null){
          this.supplierBonusIvaAmount = response.data.s176.amount;
        }

        this.supplierBonusTotalAmount = this.supplierBonusAmount + this.supplierBonusIvaAmount;
      }
      catch(e){
        this.warn(e);
      }
    },
    async getProjectData() {
      try{
        // ?filter[status]=3 // ejemplo de filtro
        let params = "with[]=" + requiredObjects.join("&with[]=");
        let response = await axios.get(`/api/v2/projects/${this.id}?${params}`);
        this.base = response.data;
        this.isMaster = this.base.splits.length > 0;
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    processError(code){
      this.onError = true;
      this.errorCode = code;
    },

    async getGeneralInfo(){
      try{
        let response = await axios.get("/api/supplier/general");
        this.general = response.data;
        this.missing = response.data.missing;
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    async saveGeneralInfo(){
      let ok = false;
      try{
        await axios.post("/api/supplier/general", this.general);
        ok = true;

        // call to update general info
        await this.onUserInfoUpdated();
      }
      catch(e){
        this.warn(e);
        ok = false;
        this.errorMssg = e;
      }
      return ok;
    },
    async getFormInfo(){
      try{
        let id = this.isMoral == true ? this.flow_id_pm : this.flow_id_pfae;
        let response = await axios.get("/api/v1/forms/getFlowSectionFields/" + id);
        let url = this.ApiDomain + `/storagev3/documents/flow/${id}`;
        let response2 = await axios.get(url);
        this.documentsStates = response2.data;

        this.flow = response.data.flow;
        this.allSections = response.data.sections;

        this.allSections.forEach(s => {
          if(this.tabs.filter(tt => tt.sections.includes(s.id)).length < 1){
            return;
          }

          s.documentFields = [];
          let co = 0;
          s.fields.forEach(f => {
            this.formFieldSetter(f, this);
            if(f.special_definition == 1) return;
            if (this.isAutoFieldToSave(f)) {co += 1}

            // check for documents
            if(f.doc_id != null){
              let ff = {formField: f, documentSet: null, target_id: null };
              let ss = this.documentsStates.find(sid => sid.id == s.id);
              if(ss && ss.documents){
                let dset = ss.documents.find(d => d.id == f.doc_id);
                if(dset){
                  ff.target_id = this.docTargetEvaluator(dset.clasification, dset.clasification_2);
                  ff.documentSet = dset;
                }
              }
              s.documentFields.push(ff);
            }
          });

          if(co == 0){
            s.skipBtn = true;
          }
        });
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    async getCollections(){
      try {
        let collectionsObjects = ['countriesList', 'statesList', 'familyGroups'];
        let params = "with[]=" + collectionsObjects.join("&with[]=");
        let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
        this.collections.countries = res.data.countriesList;
        this.collections.states = res.data.statesList;
        this.collections.family_groups = res.data.familyGroups;
      }
      catch (e) {
        this.warn(e);
        this.failed = true;
      }
    },

    /* on_change methods */
    async reload_cities(){
      try {
        let res = await axios.get('/api/register/cities/' + this.address.address$state_id);
        this.collections.cities = res.data;
        if(this.isMounted) this.address.address$city_id = null;
      } catch (e) {
        this.warn(e);
      }
    },
    async reload_cities_fiscal(){
      try {
        let res = await axios.get('/api/register/cities/' + this.address.fiscal_address$state_id);
        this.collections.fiscal_cities = res.data;
        if(this.isMounted) this.address.fiscal_address$city_id = null;
      } catch (e) {
        this.warn(e);
      }
    },
    curp_conditions_changed(){
      let c = this.contact.curp;
      if(c.length >= 10){
        let yy = c.slice(4,6);
        let mm = c.slice(6,8);
        let dd = c.slice(8,10);

        let date = this.getDateFromInputs(yy, mm, dd);
        if(date === false){
          this.contact.birth_date = null;
        }
        else{
          this.contact.birth_date = date;
        }
      }
      else{
        this.contact.birth_date = null;
      }

      if(c.length >= 11){
        let g = c.slice(10, 11);
        if(g == "H"){
          this.contact.gender = 1;
        }
        else if(g == "M"){
          this.contact.gender = 2;
        }
        else{
          this.contact.gender = null;
        }
      }
      else{
        this.contact.gender = null;
      }
    },

    /* validaciones especiales */
    validate_user_birthdate(val){
      let years = this.calculateAgeFromDate(val);
      return years >= 18;
    },
    validate_supplier_family_groups(val){
      return this.supplierFamilyGroups.filter(f => f.checked == true).length > 0;
    },
    validate_supplier_tools(val){
      return this.supplierTools.filter(f => f.checked == true).length > 0;
    },

    /* emits */
    onHandlePaymentSelected(p){
      this.sidebarData = p;
      this.$store.commit('TOGGLE_IS_PROJECT_PAYMENTS_POPUP_ACTIVE', true);
    },
  }
}
</script>