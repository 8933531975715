<template>
  <div class="vx-col w-full mb-5">
    <h5 v-if="role == 'obligated_missing'" class="text-danger mb-4 mt-4">
      Debes crear un Obligado Solidario antes de poder ingresar fuentes de ingreso
    </h5>
    <h6 v-if="role == 'pf_income_source'" class="mb-4">
      {{ f.indication }}
    </h6>
    <h6 v-else>A continuación podrás agregar una o más fuentes de ingresos. Recuerda que cada fuente deberá contar con al menos 3 comprobantes</h6>
    <vs-button
      color="primary"
      type="border"
      icon-pack="feather"
      class="mb-4 mt-4"
      @click="addIncomeSource()"
      :disabled="role == 'obligated_missing'"
      v-if="!hasBackofficeAccess">
        Agregar fuente
    </vs-button>

    <vs-table
      stripe
      :data="incomeSources"
      noDataText="No se han generado registros"
    >
      <template slot="thead">
        <vs-th class="bold">FUENTE DE INGRESOS</vs-th>
        <vs-th class="bold">ESTADO</vs-th>
        <vs-th class="bold">ACCIONES</vs-th>
      </template>
      
      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].id">
            {{ data[indextr].income_sources.name }}
          </vs-td>

          <vs-td :data="data[indextr].id" class="table-wrap">
            <span :class="getIncomeSourceStatusClass(data[indextr].id)">{{ getIncomeSourceStatus(data[indextr].id) }}</span>
          </vs-td>

          <vs-td :data="data[indextr].id" v-if="!hasBackofficeAccess">
            <feather-icon
              icon="EditIcon"
              svgClasses="w-5 h-5 hover:text-warning stroke-current cursor-pointer"
              class="mr-3"
              @click.stop="editItemRequest(data[indextr])"
            />
            <feather-icon
              icon="TrashIcon"
              svgClasses="w-5 h-5 hover:text-warning stroke-current cursor-pointer"
              class="ml-3"
              @click.stop="removeItemRequest(data[indextr])"
            />
          </vs-td>
          <vs-td :data="data[indextr].id" v-else>
            <feather-icon
              icon="EyeIcon"
              svgClasses="w-5 h-5 hover:text-warning stroke-current cursor-pointer"
              class="mr-3"
              @click.stop="editItemRequest(data[indextr])"
            />
          </vs-td>
        </vs-tr>
      </template>

    </vs-table>

    <vs-popup
      title="Selección de tipo de fuente de ingresos del solicitante."
      :active.sync="showFormPrompt"
    >
      <div class="con-exemple-prompt">
        <vs-alert v-if="onError" class="h-full mb-5" color="danger">
          <span>{{ errorMssg }}</span>
        </vs-alert>
        <vs-alert v-if="onSuccess" class="h-full mb-5" color="success">
          <span>{{ successMssg }}</span>
        </vs-alert>

        <p class="mb-5 bold">{{ formPromptIndication }}</p>

        <label for="" class="vs-input--label"
          >Fuente de ingresos</label
        >
        <v-select
          name="family_group_id"
          label="text"
          class="vs-custom w-full mb-5"
          v-model.lazy="selectedIncomeSourceId"
          :options="getIncomeSourcesForSelect"
          :reduce="(text) => text.value"
          :clearable="false"
          :searchable="false"
          placeholder="Seleccione una opción"
        >
          <div slot="no-options">No hay coincidencias</div>
        </v-select>
        <v-error
          v-if="hasError('selectedIncomeSourceId')"
          :error="errorText('selectedIncomeSourceId')"
          :success="isSuccess('selectedIncomeSourceId')"
        />

        <vs-button
          :disabled="!completeForm || $isLoading"
          @click="addIncomeSourceToApplicant"
          color="primary"
          class="mr-5 mt-2"
          >Agregar</vs-button
        >
        <vs-button
          @click="closeFormPrompt"
          :disabled="$isLoading"
          type="flat"
          color="primary"
          >Cerrar</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper"
const indicationDef = "Selecciona una fuente de ingresos para el solicitante.";
const errorMssgDef = "Ha ocurrido un error, intente más tarde.";
const successMssgDef = "El registro ha sido agregado exitosamente";
const roles = ["pf_income_source", "pf_income_source_obligated", "pm_income_source", "pm_income_source_obligated"];

export default {
    name: 'income-sources-selector-control',
    mixins: [inputHelper],
    props: {
        project: {type: Object, required: true},
        f: { type: Object, required: true },
        p_type: {type: [String, Number], required: true},
        isDisabled: { type: [String,Boolean], default: false },
        role: { type: String, default: "pf_income_source"}
    },
    data() {
      return {
        showFormPrompt: false
        , onError: false
        , onSuccess: false
        , errorMssg: errorMssgDef
        , successMssg: successMssgDef
        , formPromptIndication: indicationDef
        , getIncomeSourcesForSelect: []
        , selectedIncomeSourceId: null
        , incomeSources: []
        , incomeSourceSelected: {}
        , roles: roles,
        profileId: null

        // BASE INFORMATION
        , personal_profile_id: null
        , incomeSourceValidation: null
      }
    },
    async mounted() {
      this.showLoading(true);
      this.setBaseInformation();
      await this.getIncomeSources();
      await this.getIncomeSourcesForApplicant();
      this.showLoading(false);
    }, 
    computed: {
      getPersonType() {
        switch(this.role) {
          case 'pf_income_source': return this.p_type;
          case 'pf_income_source_obligated': return 1;
          case 'pm_income_source_obligated': return 0;
          case 'pm_income_source': return 0;
        }
      },
      completeForm(){
        return this.selectedIncomeSourceId != null;
      },
      hasBackofficeAccess(){
        return this.$acl.check('isBackoffice');
      },
      current_project_id(){
        return this.project.id;
      },
      personal(){
        return this.project.client.user.personal;
      },
      pf_guarantee_personal(){
        return this.personal.guarantee_profile.personal;
      }
    },
    methods: {
      addIncomeSource(){
        this.$router.push({ name: 'editarIncomeSourcesDeSolicitante', params: { id: this.current_project_id, income_source_id: -1, role: this.role } })
      },
      resetForm(){
        this.selectedIncomeSourceId = null
        this.onError = null
        this.onSuccess = null      
      },
      closeFormPrompt() {
        this.showFormPrompt = false;
        this.resetForm();
      },
      async getIncomeSources(){
        if(this.role == 'obligated_missing')
          return;
        
        try{
          let res = await axios.get(`/api/v1/forms/${this.getPersonType}/getIncomeSources`);
          let sources = res.data;

          let options = [];

          sources.forEach(s => {
            options.push({value: s.id, text: s.name});
          });
          this.getIncomeSourcesForSelect = options;
        } catch (error) {

        }
      },
      hasError(val){
        return this.errors.has(val);
      },
      errorText(val){
        return this.errors.first(val);
      },
      isSuccess(val){
        let ff = this.fields[val];
        return (ff && ff.required && ff.valid);
      },
      async addIncomeSourceToApplicant(){
        this.onSuccess = false;
        this.onError = false;

        this.showLoading(true);
        try {
          const payload = {
            profileId: this.profileId,
            incomeSourceId: this.selectedIncomeSourceId,
            role: this.role
          };
          let res = await axios.post(`/api/v1/forms/createIncomeSource`, payload);
          let new_pp_income_id;

          if(res.data.success != true){
            this.errorMssg = res.data.error;
            this.onError = true;
          }
          else {
            new_pp_income_id = res.data.id;
            this.onSuccess = true;
            this.closeFormPrompt();
    
            // redireccionar a la vista de edicion
            this.$router.push({ name: 'editarIncomeSourcesDeSolicitante', params: { id: this.current_project_id, income_source_id: new_pp_income_id, role:this.role } })
          }
        }
        catch (error) {
          this.warn(error);
          this.onError = true;
        }
        this.showLoading(false);

      },
      async getIncomeSourcesForApplicant(){
        if(this.role == 'obligated_missing')
          return;

        try{
          let res = await axios.get(`/api/v1/forms/${this.profileId}/${this.role}/getProfileIncomeSources`);
          this.incomeSources = res.data;

          let resValidations = await axios.get(`/api/v1/income_sources/${this.profileId}/${this.role}/verify`)
          this.incomeSourceValidation = resValidations.data;

        } catch (error) {
          console.log(error);
        }
      },
      editItemRequest(income_sources){
        this.$router.push({ name: 'editarIncomeSourcesDeSolicitante', params: { id: this.current_project_id, income_source_id: income_sources.id, role: this.role } })
      },
      removeItemRequest(income_source){
        this.incomeSourceSelected = income_source;

        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: 'Descartar registro',
          text: `¿Realmente desea eliminar el registro? Todos los datos registrados para esta fuente de ingresos también serán descartados`,
          acceptText: "Eliminar",
          cancelText: 'Cancelar',
          accept: this.doRemoveItem
        });
      },
      async doRemoveItem(){
        this.showLoading(true);
        try {
          let payload = {income_source_id: this.incomeSourceSelected.id, role: this.role};
          await axios.post('/api/v1/income_sources/delete_income_source', payload);
          this.successNotif("Operación exitosa", "Registro descartado correctamente");
          await this.getIncomeSourcesForApplicant();
          this.$emit("updated", 1);
        }
        catch (error) {
          this.warn(error);
        }
        this.showLoading(false);
      },
      setBaseInformation(){
        switch(this.role){
          case "pf_income_source":
            this.profileId = this.personal.id;
            break;
          case "pf_income_source_obligated":
            this.profileId = this.project.finance.guarantee.guarantee_profile.personal.id;
            break;
          case "pf_income_source_owner":
            this.profileId = this.project.client.user.business.owner_personal_profile.id;
            break;
          case "pf_income_source_representative":
            this.profileId = this.project.client.user.business.personal.id;
            break;
          case "pm_income_source_obligated":
            this.profileId = this.project.finance.guarantee.guarantee_profile.business.id;
            break;
          case "pm_income_source_owner":
            this.profileId = this.project.client.user.business.owner_business_profile.id;
            break;
          case "pm_income_source":
            this.profileId = this.project.client.user.business.id;
            break;
          default: console.log("NO SE DEFINIÓ");
        }
      },
      getIncomeSourceStatus(id){
        if(!this.incomeSourceValidation){
          return null;
        }
        const validation = this.incomeSourceValidation.validations.find(x => x.income_source_id == id );
        if(!validation.employeeDataValidations || validation.employeeDataValidations.complete){
          let proofsComplete = validation.proofsDataValidations.findIndex(item => item.completeProof == false);
          if(proofsComplete != -1){
            return "Info. de comprobante de ingresos faltante."
          } else {
            return "Info. y documentos completos."
          }
        } else {
          return "Info. de fuente de ingresos faltante.";
        }
      },
      getIncomeSourceStatusClass(id){
      if(!this.incomeSourceValidation){
        return "";
      }
      
      const validation = this.incomeSourceValidation.validations.find(x => x.income_source_id == id )
      if(!validation.employeeDataValidations || validation.employeeDataValidations.complete){
        let proofsComplete = validation.proofsDataValidations.findIndex(item => item.completeProof == false);
        if(proofsComplete != -1){
          return "text-danger";
        }
        return "text-success";
      } else {
        return "text-danger";
      }
    },
    }
};
</script>

<style>
</style>