<template>
  <div class="vx-row mb-4">
    <div class="vx-col xxl:w-70% xl:w-60% lg:w-full md:w-full sm:w-full">
      <h6 class="mb-3">{{project.alias}}</h6>
      <div class="demo-alignment custom">
        <div v-for="(pill, idx) in pills" v-bind:key="idx" class="project-status-chip mb-4" :class="getClass(pill.status)">
          <vs-chip              
            transparent
            class="mb-3"
            :color="getColorClass(pill.status)">
            <vs-avatar icon-pack="feather" :icon="getIcon(pill.status)" />{{ pill.name }}
          </vs-chip>
          <p class="pill-desc mt-2">{{getDate(pill.status)}}</p>
        </div>
      </div>
    </div>

    <div class="vx-col xxl:w-30% xl:w-40% lg:w-full md:w-full sm:w-full mb-3" v-if="showFundingProgress">
      <project-fund-progress
          :total="project.finance.financed_amount"
          :invested="project.invested"
          :percentage="project.percentage"
          :remaining_days="project.dias_restantes"
          :investors="project.inversionistas"
          />
    </div>    
  </div>
</template>
<script>
import dateHelper from '@mixins/dateHelper';
import ProjectFundProgress from '@components/supplier/projects/ProjectFundProgress.vue'

export default {
  name: "split-project-status-pills",
  props: ['project'],
  mixins: [dateHelper],
  components: {
    ProjectFundProgress
  },
  data () {
    return {
      pills: [
        {status: 2, name: "En revisión"},
        {status: 3, name: "Pre-aprobado"},
        {status: 4, name: "Aprobado"},
        {status: 5, name: "En fondeo"},
        {status: 6, name: "Firma de contratos"},
        {status: 7, name: "En instalación"},
        {status: 8, name: "Entregado"},
      ],
    }
  },
  computed: {
    showFundingProgress(){
      return this.project.status >= 5 && this.project.status <= 8;
    },
  },
  methods: {
    getClass(s){
      if(s < this.project.status){
        return "completed";
      }
      else if(s == this.project.status){
        return "current";
      }
      else {
        return "na";
      }
    },
    getColorClass(s){
      if(s < this.project.status){
        return "success";
      }
      else if(s == this.project.status){
        return "warning";
      }
      else {
        return "na";
      }
    },
     getIcon(s){
      if(s < this.project.status){
        return "icon-check";
      }
      else if(s == this.project.status){
        return "icon-play";
      }
      else {
        return "icon-lock";
      }
    },
    getDate(s){
      let dd = null;
      switch(s) {
        case 2:
          dd = this.project.review_date;
          break;
        case 3:
          dd = this.project.preapproved;
          break;
        case 4:
          dd = this.project.approved_date;
          break;
        case 5:
          dd = this.project.fund_date;
          break;
        case 6:
          dd = this.project.contracts_date;
          break;
        case 7:
          dd = this.project.installation_date;
          break;
        case 8:
          dd = this.project.delivery_date;
          break;
      }
      if(dd != null){
        return this.compactDateFormat(dd);
      }
      else {
        return "Pendiente";
      }
    }
  }
}
</script>

<style scoped>

.project-status-chip .pill-desc {
  text-align: center;
  font-size: 13px;
}

.project-status-chip.completed .pill-desc {
  color: rgba(var(--vs-success),1);
}
.project-status-chip.current .pill-desc {
  color: rgba(var(--vs-warning),1);
}
.project-status-chip .pill-desc {
  color: rgba(0,0,0,.7);
}
.demo-alignment.custom > *{
  margin-right: 1rem;
}
</style>