<template>
<div class="vx-col w-full">

  <div class="" id="main-div" >     

    <vs-table stripe ref="table" v-model="paymentSelected" max-items="10" @selected="handleSelected" :data="payments" noDataText="No hay datos disponibles">
      <template slot="thead">
        <vs-th class="bold" v-for="(col, idx) in columns" v-bind:key="idx" :sort-key="col.sortable ? col.key : null" >{{col.title}}</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data" :data="tr">
          <vs-td>
            {{ tr.concept }}
          </vs-td>
          <vs-td>
            {{ costFormat(tr.amount) }}
          </vs-td>
          <vs-td>
            {{ statusStr(tr.status) }}
          </vs-td>

          <vs-td>
            {{ dateStr(tr) }}
          </vs-td>

          <vs-td :data="tr.id">
            <div class="vx-row">              
              <vs-button v-if="tr.rg_invoice_id !== null" size="small" type="border" class="mb-2 mr-2" target :href="$sanitizeUrl(`${ApiDomain}/storage/private/rg-invoice/0/${tr.pdf_file}?access_token=${accessToken}`)" >PDF</vs-button>
              <vs-button v-if="tr.rg_invoice_id !== null" size="small" type="border" class="mb-2" target :href="$sanitizeUrl(`${ApiDomain}/storage/private/rg-invoice/0/${tr.xml_file}?access_token=${accessToken}`)" >XML</vs-button>
            </div>
          </vs-td>

        </vs-tr>
      </template>
    </vs-table>

  </div>
</div>

</template>

<script>
import formatHelper from '@mixins/formatHelper';
import dateHelper from '@mixins/dateHelper';
import Datepicker from "vuejs-datepicker";
import { es } from "vuejs-datepicker/dist/locale";
import {mapState} from "vuex";

const columnDefinitions = [
  { title: "DESCRIPCIÓN", key: "concept", sortable: true },
  { title: "TOTAL", key: "amount", sortable: true },
  { title: "ESTADO", key: "status", sortable: true },
  { title: "FECHA DE PAGO", key: "status", sortable: true },
  { title: "FACTURA", key: "invoice", sortable: false }
];

const itemsPerPageDefinitions = [5,10,25,50];

export default {
  name: "project-payments-invoices",
  props: ['project_id'],
  mixins: [formatHelper, dateHelper],
  components: {    
    Datepicker
  },
  async mounted() {
    this.supplier_id = this.SupplierId;
    await this.loadPayments();
  },
  data() {
    return {
      payments: [],
      allPayments : [],
      orderKey: "",
      projectIdToDelete: -1,
      columns: columnDefinitions,
      itemsPerPageDefinitions: itemsPerPageDefinitions,
      itemsPerPage: itemsPerPageDefinitions[0],
      isMounted: false,
      loading: false,
      paymentSelected: null,
      // Data Sidebar
      showSideBar: false,
      sidebarData: {},
      es: es,

      supplier_id: null,

      filters:{
        q: "",
        date: {
          start: "",
          end: ""
        }
      }
    };
  },
  computed: {
    ...mapState("auth", ["access_token"]),
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.payments.length
    },
    accessToken(){
      return this.access_token;
    },
  },
  watch: {
    loading: function(val){
      if(val) {
        this.$vs.loading({
          container: '#main-div',
          scale: 1
        })
      }
      else {
        this.$vs.loading.close('#main-div > .con-vs-loading')
      }
    },
  },
  methods: {
    async loadPayments(){
      this.isMounted = false;
      this.loading = true;

      try {
        let response = await axios.get(`/api/project/${this.project_id}/supplier/payments`);
        this.allPayments = response.data;
        // no mostrar los que estan en estatus creado
        this.payments =  this.allPayments.filter(p => p.status > 0);
        this.$emit("loadedPayments", this.payments.length);              
      } catch (error) {}
      this.isMounted = true;
      this.loading = false;    
    },
    async clearSearch(){
      this.filters.q = this.filters.date.start = this.filters.date.end = "";
      await this.loadPayments();
    },
    async onEnter(){
      await this.loadPayments();
    },
    valOrPending(value){
      return value == 0 ? 'Pendiente' : value;
    },
    statusStr(value){
      if(value == 0){
        return "Creado"
      }
      else if(value == 1){
        return "En Proceso"
      }
      else if (value == 2){
        return "Pagado"        
      }
      else {
        return "-"
      }
    },
    dateStr(payment){
      if(payment.status == "0" || payment.status == "1" || payment.trx_confirmation_date == "0000-00-00 00:00:00"){
        return "--";
      }
      else {
        return this.dateTimeFormat(payment.trx_confirmation_date);
      }
    },
    handleSelected(p){
      this.$emit('on-payment-selected', p);
    },
     toggleDataSidebar (val = false) {
      this.showSideBar = val
    }
  },
};
</script>


<style lang="css">
  .bg-gray {
    background-color: gray;
  }
  .vx-input-group .append-text.btn-addon .vs-button.search-btn {
    padding: 0 1rem !important;
  }
</style>
