<template functional>
   <div class="vx-col w-full mb-1 mt-2">
      <p><strong>Datos de la dirección de su empleo:</strong></p>
      <vs-divider></vs-divider>
   </div>
</template>

<script>
export default {
   name: 'address-divider'
}
</script>