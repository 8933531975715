<template>
  <vx-card class="p-4">
    <template v-if="hasinfo" slot="no-body">
      <div class="text-center">
        <h4 class="mb-2">{{get_group_name}}</h4>
        Detalle
      </div>
      <div class="mb-4 mt-3">
        <div class="">
            <p><strong>Estado: </strong><span>{{get_status}}</span></p>
            <p><strong>Fecha creación: </strong><span>{{get_creation_date}}</span></p>
            <p><strong>Fecha límite de firma: </strong><span>{{get_signing_due_date}}</span></p>
            <p><strong>Tipo de firma: </strong><span>{{get_wish_type}}</span></p>
            <p><strong>Requisito para: </strong><span>{{get_type_payment}}</span></p>
        </div>
      </div>
    </template>
    <template v-else>
        <div class="text-center">
            <h4 class="mb-2">{{get_group_name}}</h4>
            Detalle
        </div>
        <div class="mb-4 mt-3">
         <template v-if="get_project_status < 6 || !hasSerialNumbers">
            <p><strong>Estado: </strong><span>Pendiente</span></p>    
            <p ><strong>Acciones: </strong></p>    
            <ul style="list-style-type: initial !important;">
                <li v-if="get_project_status < 6">Fondeo exitoso</li>
                <li v-if="!hasSerialNumbers">Agregar números de serie</li>
            </ul>
         </template>
         <template v-else>
            <p class="bold">Ya puedes solicitar la creación de los contratos al equipo de RedGirasol.</p>
         </template>
        </div>
    </template>
  </vx-card>
</template>

<script>
import dateHelper from "../../mixins/dateHelper";
import formatHelper from '../../mixins/formatHelper';

export default {
  name: 'project-contract-progress',
  props: ['contract_group', 'index', 'contract_groups', 'project', 'hasSerialNumbers'],
  mixins: [dateHelper,formatHelper],
  methods: {
   
  },
  computed: {
    get_group_name(){
        let i = this.index + 1;
        if(this.contract_group.length != 0){
            return this.contract_group[0].group.name;
        } else {
            if(this.contract_groups != 0){
                let group = this.contract_groups.find(x => x.id == i);
                return group.name;
            } else {
                return "Grupo " + i;
            }
        }
    },
    hasinfo(){
        return this.contract_group.length != 0;
    },
    get_status(){
        let status = "";
        switch(this.contract_group[0].status){
            case "sent": 
                status = "Enviados"; 
                break;
            case "generated": 
                status = "Generados"; 
                break;
            case "signed": 
                status = "Firmados"; 
                break;
            case "cancelled": 
                status = "Cancelados"; 
                break;
                default: status = "--";
        }
        return status;
    },
    get_creation_date(){
        return this.regularDateFormat(this.contract_group[0].created_at);
    },
    get_signing_due_date(){
        if(this.contract_group[0].signing_due_date != null && this.contract_group[0].signing_due_date != "0000-00-00 00:00:00"){
            return this.regularDateFormat(this.contract_group[0].signing_due_date)
        } else {
            return "--";
        }
    },
    get_wish_type(){
        let r = "";
        switch(this.contract_group[0].created_at){
            case 1:
            r = "Manual Autógrafa";
            break;
            case 2:
            r = "Electrónico";
            break;
            case 3:
            r = "Firma Autógrafa Digital (FAD)";
            break;
            default: r = "--";
        }
        return r;
    },
    get_type_payment(){
        if(this.index == 0 || this.index == 1){
            return "Anticipo"
        } else {
            return "Finiquito"
        }
    },
    get_project_status(){
        return this.project.status
    }
  }
}
</script>