<template>
  <div class="mid-timeline">
    <vx-timeline :data="getPills" :isCustom="false" :isHorizontal="isHorizontal"></vx-timeline>
  </div>
</template>
<script>
import dateHelper from '../../mixins/dateHelper';
export default {
  name: "single-project-status-pills",
  props: ['project', 'isHorizontal'],
  mixins: [dateHelper],
  data () {
    return {
      pills: [
        {status: 1, name: "Solicitud"},
        {status: 2, name: "Documentación"},
        {status: 3, name: "En revisión"},
        {status: 4, name: "Aprobado"},
        {status: 5, name: "En fondeo"},
        {status: 6, name: "Firma de contratos"},
        {status: 8, name: "Entregado"},
      ],
    }
  },
  computed: {
    isSFV() {
      return this.project.finance.loan_type == 1;
    },
    getPills(){

      if(this.isSFV) {
       this.pills = [
          {status: 1, name: "Solicitud"},
          {status: 2, name: "Documentación"},
          {status: 3, name: "En revisión"},
          {status: 4, name: "Aprobado"},
          {status: 5, name: "En fondeo"},
          {status: 6, name: "Firma de contratos"},
          {status: 7, name: "En instalación"},
          {status: 8, name: "Entregado"},
        ];
      }
      
      let index = 1;
      return this.pills.map(p => {
        let date = this.newGetDate(p.status);
        let color = "grey-light";
        let icon = "MoreHorizontalIcon";
        let icon_color = "grey";
        
        // Sí es el último status = 8 no se suma nada
        if(p.status == 8) {
          index = 0;
        } else if(!this.isSFV && p.status == 6) { // Sí no es SFV y el status es 6, se suman 2 
          index = 2;
        } else { // a todos los demás, se sube 1.
          index = 1;
        }
        // Se consulta la siguiente fecha del collar para saber si el icono puede cambiar.
        let nextDate = this.newGetDate(p.status + index);
        
        // Se marca la fecha como completa pero no el icono.
        if(date != "Pendiente") {
          color = "rg-green-light";
          // icon = "CheckIcon";
          icon_color = "rg-green-accent";
        }
        
        // El icono se marca si la fecha siguiente ya está completa.
        if(nextDate != "Pendiente") {
          icon = "CheckIcon";
        }

        // En caso de que se rechace la solicitud.
        if(p.status == 3 && this.project.rejected_date != null) {
          color = "rg-red-light";
          icon = "MoreHorizontalIcon";
          icon_color = "rg-red-accent";
        }
        
        // En caso de que sea archivado.
        if(p.status == 3 && this.isArchived) {
          color = "rg-red-light";
          icon = "MoreHorizontalIcon";
          icon_color = "rg-red-accent";
        }

        return {
          title: p.name,
          desc: date,
          color: color,
          icon: icon,
          icon_color: icon_color
        }
      })
    },
    isArchived()
    {
        return this.project.resolution_date != null;
    }
  },
  methods: {
    getClass(s){
      if(s < this.project.status){
        return "completed";
      }
      else if(s == this.project.status){
        return "current";
      }
      else {
        return "na";
      }
    },
    getColorClass(s){
      if(s < this.project.status){
        return "success";
      }
      else if(s == this.project.status){
        return "warning";
      }
      else {
        return "na";
      }
    },
     getIcon(s){
      if(s < this.project.status){
        return "icon-check";
      }
      else if(s == this.project.status){
        return "icon-play";
      }
      else {
        return "icon-lock";
      }
    },
    getDate(s){
      let dd = null;
      switch(s) {
        case 1:
          dd = this.project.created_at;
          break;
        case 2:
          dd = this.project.loan_review_request_timestamp;
          break;
        case 3:
          dd = this.project.documentation_complete_date;
          break;
        case 4:
          dd = this.project.loan_proposal_timestamp;
          break;
        case 5:
          dd = this.project.public_date;
          break;
        case 6:
          dd = this.project.fund_date;
          break;
        case 7:
          dd = this.project.installation_date;
          break;
        case 8:
          dd = this.project.delivery_date;
          break;
      }
      if(dd != null){
        return this.regularDateFormat(dd);
      }
      else {
        return "Pendiente";
      }
    },
    newGetDate(s){
      let dd = null;
      switch(s) {
        case 1:
          dd = this.project.created_at;
          break;
        case 2:
          dd = this.project.loan_review_request_timestamp;
          break;
        case 3:
          dd = this.project.documentation_complete_date;
          break;
        case 4:
          dd = this.project.loan_proposal_timestamp;
          break;
        case 5:
          dd = this.project.public_date;
          break;
        case 6:
          dd = this.project.contracts_created_at;
          break;
        case 7:
          dd = this.project.installation_date;
          break;
        case 8:
          dd = this.project.delivery_date;
          break;
      }
      if(dd != null){
        return this.regularDateFormat(dd);
      }
      else {
        return "Pendiente";
      }
    }
  }
}
</script>

<style scoped>

.project-status-chip .pill-desc {
  text-align: center;
  font-size: 13px;
}

.project-status-chip.completed .pill-desc {
  color: rgba(var(--vs-success),1);
}
.project-status-chip.current .pill-desc {
  color: rgba(var(--vs-warning),1);
}
.project-status-chip .pill-desc {
  color: #F4F4F4;
}
.demo-alignment.custom > *{
  margin-right: 1rem;
}


@media only screen and (max-width: 600px) {
  .mid-timeline {
    height: 8rem;
    overflow-x: auto;
    overflow-y: hidden;
  }
}

/* @media only screen and (min-width:636px) and (max-width:768px) {
  .horizontal {
    display: flex;
  }
  .md-timeline {
      height: 8rem;
      overflow: auto;
    }
} */
</style>