<template>
  <vx-card title="Aceptación del crédito">
    <p>¡Felicidades! El crédito ha sido aprobado para tu cliente por lo que ahora es necesario que él acepte el crédito. 
      Las condiciones del crédito son las siguientes: <br><br></p>

    <p><strong>Mensualidad: </strong>{{monthly_payment}} MXN</p>
    <p><strong>Tasa anual: </strong>{{annual_percentage}}</p>
    <p><strong>Plazo: </strong> {{term}} meses</p>
    <vs-divider></vs-divider>

    <p>Hemos enviado un correo a tu cliente con la liga para que pueda realizar la aceptación del crédito. <br/> <br/></p>
    <p><strong>Puedes enviarle a tu cliente un recordatorio por correo presionado el siguiente botón:</strong></p>

    <!-- Save Button -->
    <div class="vx-row mt-4">
      <div class="vx-col w-full">
        <div class="flex justify-between flex-wrap">
            <vs-button class="mt-4" color="primary" @click="sendReminder()" >Enviar recordatorio por correo</vs-button>
        </div>
      </div>
    </div>

  </vx-card>
</template>
<script>
import formatHelper from '@components/mixins/formatHelper';
export default {
  name: 'activate-account',
  props: ['applicantUserId', 'project_id', 'project'],
  mixins: [formatHelper],
  components: {    
  },
  computed:{
    hasFinance(){
      return this.project.finance != null;
    },
    finance(){
      return this.project.finance;
    },
    monthly_payment(){
      if(this.hasFinance){
        return this.explicitMoneyFormat( this.finance.monthly_payment);
      }
    },
    annual_percentage(){
      if(this.hasFinance){
        return this.prcgFormat(this.finance.annual_interest_percentage * 100, 2);
      }
    },
    term(){
      if(this.hasFinance){
        return this.finance.term;
      }
    }
  },
  methods: {
    async sendReminder(){
      this.showLoading(true);
      try {        
        await axios.get('/api/prefund/sendAcceptance/' + this.project_id);
        this.successNotif("Correo enviado", "Se ha enviado el correo exitosamente", 8000);
      } 
      catch (error) {
        this.errorNotif("Error en el envío", "Ha ocurrido un error al intentar enviar el correo electrónico, por favor intente más tarde", 8000);
        this.warn(error);  
      }
      this.showLoading(false);
    },
    timeout(ms){
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }
}
</script>