<template>
  <div>
    <!-- SECCIONES -->              
    <accept-credit v-if="isMounted && !creditAccepted" :applicantUserId="applicantUserId" :project_id="id" :project="project" ></accept-credit>
    <initial-payment v-if="isMounted && creditAccepted" :applicantUserId="applicantUserId" :project_id="id" :project="project" ></initial-payment>   

    <load-error v-if="failed" />

  </div>
</template>

<script>
import { mapState } from 'vuex';
import inputHelper from '@components/mixins/inputHelper';
import dateHelper from '@components/mixins/dateHelper';
import formHelper from '@components/mixins/formHelper';
import FormField from '@components/forms/FormField.vue'
import AcceptCredit from '@components/supplier/projects/AcceptCredit.vue'
import InitialPayment from '@components/supplier/projects/InitialPayment.vue'

export default {
  mixins: [formHelper, inputHelper, dateHelper],
  props: ['id', 'project', 'applicantUserId'],
  data(){
    return {
      errorCode: 0,
      onError: false,
      metaData: {},

      isMounted: null,
      failed: null,
      creditAccepted: false,
      activeTab: 0,
    }
  },
  components: {
    FormField,
    AcceptCredit,
    InitialPayment
  },
  computed: {
    ...mapState("auth", ["user"]),
    userId: function() {
      return this.user.user_id;
    },
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  async mounted() {
    if(this.project.finance){
      this.creditAccepted = this.project.finance.credit_acceptance_date != null;
    }
   this.isMounted = true
  },
  watch: {
    loading : function(newVal, oldVal){
      if(newVal == true){
         this.$vs.loading();
      }
      else {
        this.$vs.loading.close();
      }
    },    
  },
  methods: {
  }
}
</script>