<template>

  <div class="vx-col w-full" v-if="isMounted">
    <div class="vx-row" >
      <document-upload
        v-for="(df, index) in pdfStates"
        :key="df.id"
        :target_id="project_id"
        :f="pdfFormField"
        :doc="pdfDocumentSet"
        :loadedState="df.stateDef"
        :disabled="isDisabled"
        :inputClassLarge="true"
        :multi_assoc_index="index"
        :from_project_id="project_id"
        :evaluator="evaluateCondition"
        />

      <document-upload
        v-for="(df, index) in xmlStates"
        :key="df.id"
        :target_id="project_id"
        :f="xmlFormField"
        :doc="xmlDocumentSet"
        :loadedState="df.stateDef"
        :disabled="isDisabled"
        :inputClassLarge="true"
        :multi_assoc_index="index"
        :from_project_id="project_id"
        :evaluator="evaluateCondition"
        />
    </div>

  </div>

</template>

<script>
import inputHelper from '@components/mixins/inputHelper';
import dateHelper from '@components/mixins/dateHelper';
import formHelper from '@components/mixins/formHelper';
export default {
  name: 'invoices-control',
  props: ['project_id', 'isDisabled'],
  mixins: [formHelper, inputHelper, dateHelper],
  data(){
    return {
      section_id: 46,
      pdf_id: 63,
      xml_id: 64,
      isMounted: false,
      documents: [],

      pdfStates: [],
      xmlStates: [],

      pdfInvoices: [],
      xmlInvoices: [],

      pdfFiles: [],
      xmlFiles: [],
      section: {},

      pdfFormField: {},
      xmlFormField: {},

      pdfDocumentSet: {},
      xmlDocumentSet: {},

      hasAssoc: null,
      isMaster: null,

      project: {},

      docAssoc: {
        table: null,
        assoc_key: null,
        condition: null,
        assoc_source: null,
        neg_mssg: null,
        multi_assoc: null,
      },
    }
  },
  computed: {
    project_exists(){
      return true;
    },
    apiStorageBasePrefix(){
      return this.ApiDomain + "/storagev3/documents/";
    },
  },
  async mounted(){
    this.isMounted = false;
    this.showLoading(true);
    await this.getFieldsDefinition();
    await this.getInvoicesSaved();
    this.showLoading(false);
    this.isMounted = true;
  },
  methods: {
    async getInvoicesSaved(){
      try {
        let params = "with[]=splits&with[]=pdfInvoices&with[]=xmlInvoices";
        let response = await axios.get(`/api/v2/projects/${this.project_id}?${params}`);
        if(!response.data){
          return;
        }

        this.project = response.data;
        this.pdfInvoices = response.data.pdf_invoices;
        this.xmlInvoices = response.data.xml_invoices;
        this.isMaster = this.project.splits.length > 0;

        // informacion de archivos
        let url = this.apiStorageBasePrefix;
        let getUrl = `${url}files/${this.pdf_id}/getFilesByAssociated`;
        let params2 = `?assoc_table=project_invoices&assoc_key=project_id&assoc_id=${this.project_id}&assoc_relation=invoice_pdf_df_id`;
        getUrl += params2;

        let res = await axios.get(getUrl);
        this.pdfFiles = res.data;

        this.pdfStates = [];
        this.pdfFiles.forEach(df => {
          let pdf = this.pdfInvoices.find(f => f.invoice_pdf_df_id == df.id);
          this.pdfStates.push({stateDef: df, image: pdf});
        });

        if(this.pdfStates.length < 1){
          let ss = {stateDef: null, image: null};
          this.pdfStates.push(ss);
        }

        // informacion de archivos
        let url2 = this.apiStorageBasePrefix;
        let getUrl2 = `${url2}files/${this.xml_id}/getFilesByAssociated`;
        let params3 = `?assoc_table=project_invoices&assoc_key=project_id&assoc_id=${this.project_id}&assoc_relation=invoice_xml_df_id`;
        getUrl2 += params3;

        let res2 = await axios.get(getUrl2);
        this.xmlFiles = res2.data;

        this.xmlStates = [];
        this.xmlFiles.forEach(df => {
          let xml = this.xmlInvoices.find(f => f.invoice_xml_df_id == df.id);
          this.xmlStates.push({stateDef: df, image: xml});
        });

        if(this.xmlStates.length < 1){
          let ss = {stateDef: null, image: null};
          this.xmlStates.push(ss);
        }

      }
      catch (error) {
        this.warn(error);
      }
    },
    async getFieldsDefinition(){
      try{
        let response = await axios.get("/api/v1/forms/getSectionFields/" + this.section_id);
        let url = this.ApiDomain + `/storagev3/documents/section/${this.section_id}`;
        let response2 = await axios.get(url);
        this.pdfDocumentSet = response2.data.find(f => f.id == this.pdf_id);
        this.xmlDocumentSet = response2.data.find(f => f.id == this.xml_id);

        this.section = response.data.section;
        this.pdfFormField = this.section.fields.find(f => f.doc_id == this.pdf_id);
        this.pdfFormField.doc_id = this.pdf_id;

        this.xmlFormField = this.section.fields.find(f => f.doc_id == this.xml_id);
        this.xmlFormField.doc_id = this.xml_id;
      }
      catch(e){
        this.warn(e);
        this.failed = true;
      }
    },
    addImage(){
      let ss = {stateDef: null, image: null};
      this.documentsStates.push(ss);
    },

    onFieldChange(method){
      if(method != null && method in this){
        this[method]();
      }
    },
    evaluateCondition(condition){
     return this.evaluateConditionBase(this, condition);
    },
    /* obtener ids para los casos correspondientes */
    docTargetEvaluator(cls, cls_2){
      if(cls == 'project'){
        return this.project_id;
      }
      this.warn(`DOC TARGET NOT DEFINED FOR classifications: ${cls},${cls_2}`);
      return null;
    },
  }
}
</script>