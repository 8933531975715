<template>
  <div class="page-header-view">
    <!-- NUEVO DETALLE DE PROYECTO -->
    <div id="project-detail" class="vx-row mb-6 same-h" v-if="isMounted">
      <div class="vx-col w-full">
        <vx-card class="p-4 text-center">
          <h1 class="mb-2">
            {{ id }}
            <span v-if="project.alias != null && project.alias != ''">
              / {{ project.alias }}
            </span>
          </h1>
          <h4 class="opacity-75">Solicitud de {{ applicantName }}</h4>

          <!-- LINEA DEL TIEMPO -->
          <div class="vx-row mt-12">
            <div v-if="!isMaster" class="vx-col w-full">
              <single-project-status-pills v-if="!isMaster" :project="project" :isHorizontal="true" />
            </div>
          </div>

          <!-- DETALLE DE LA SOLICITUD -->
          <div v-if="!isArchived && !isMaster" class="vx-row mt-12 mb-4">
            <div class="vx-col w-full">
              <loan-request-step-detail 
                v-if="!isMaster" 
                :project="project"
                :technicalValidations="technologyValidations"
                @goToPage="goToPage"
                 />
            </div>
          </div>
          <!-- DETALLE DE LA SOLICITUD -->
          <div v-if="isArchived" class="justify-around mb-12 mb-24 mt-12 vx-row" style="text-align: left;line-height: 3rem;">
            <div class="vx-col sm:w-full md:w-1/3 black mb-4">
              <h2 class="mb-base">Solicitud archivada</h2>
              <p><strong>Archivado el: </strong>{{ fieldValue("resolution_date", "date") }}</p>
              <p class="mb-base" style="line-height: normal;"><strong>Razón de archivado: </strong>{{ archiveReason() }}</p>
              <div class="vx-col w-full flex mt-2" id="account-manage-buttons2" v-if="canUnarchiveProject">
                <vs-button color="dark" icon-pack="feather" icon="icon-archive" class="mr-4" @click="unarchiveProjectRequest()">
                  Desarchivar</vs-button>
              </div>
            </div>
            <div class="vx-col sm:w-full md:w-1/4 img-center">
              <img src="https://cdn.redgirasol.com/suppliers/resources/icons/10_loan_archived.png" alt="Solicitud rechazada">
            </div>
          </div>

          <div class="vx-row px-8 mt-16">
            <div class="vx-col sm:w-full md:w-1/2 mb-4">
              <credit-events-log :projectId="id" />
            </div>
            <div class="vx-col sm:w-full md:w-1/2 lg:w-1/2">
              <div class="vx-row justify-end">
                <div class="vx-col w-1/2 mb-4">
                  <div class="text-subtitle" v-if="hasAgent">
                    <strong> Vendedor asignado:</strong> <br>
                    {{ agentName }}
                    <!-- <vs-button color="#28DE18" gradient-color-secondary="rgb(40, 222, 24, .5)" type="gradient" v-if="!isBackoffice && !isArchived" class="p-2 w-full mt-2"
                      @click.stop="assignAgentRequest()">Reasignar</vs-button> -->
                      <p @click.stop="assignAgentRequest()" class="bold black mt-2 clickable-img"><u>Reasignar</u></p>
                  </div>
                  <div class="text-subtitle" v-else-if="!isBackoffice && !isArchived">
                    <!-- <vs-button color="#28DE18" gradient-color-secondary="rgb(40, 222, 24, .5)" type="gradient" class="p-2 w-full" @click.stop="assignAgentRequest()">Asignar vendedor</vs-button> -->
                      <p @click.stop="assignAgentRequest()" class="bold black mt-2 clickable-img"><u>Asignar vendedor</u></p>
                  </div>
                </div>
                <div class="vx-col w-1/2 mt-auto mb-4">
                  <div class="vx-col w-full flex" id="account-manage-buttons" v-if="canArchiveProject">
                    <p @click.stop="archiveProjectRequest()" class="bold black mt-2 clickable-img"><u>Archivar</u></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </vx-card>
      </div>
    </div>

    
    <!-- <div class="vx-row mb-6 same-h" v-if="isMounted">
      <div v-if="showFundingProgress && !isMaster" class="vx-col w-full">
        <project-fund-progress :total="project.finance.financed_amount" :invested="project.invested"
          :percentage="project.percentage" :remaining_days="project.dias_restantes" :investors="project.inversionistas" />
      </div>
    </div> -->

    <vs-navbar v-model="activeTab" type="gradient" class="project-navbar p-2 mb-5" color="#3B3A3E"
      text-color="rgba(var(--vs-primary)">
      <div slot="title" class="mb-1 mt-1">
        <vs-navbar-title>
          <span>DETALLE DEL PROYECTO</span>
        </vs-navbar-title>
      </div>

      <vs-navbar-item index="0" class="cursor-pointer">
        <a>Solicitud </a>
      </vs-navbar-item>

      <!-- <vs-navbar-item index="1" v-if="!isArchived && !prefundNavDisabled">
        <a>Pre-fondeo </a>
      </vs-navbar-item>
      <a class="project-navbar-disabled" v-else>Pre-fondeo</a> -->

      <vs-navbar-item index="3" v-if="!isArchived && hasContracts">
        <a>Contratos </a>
      </vs-navbar-item>
      <a class="project-navbar-disabled" v-else>Contratos</a>
      <template v-if="isSFV">
        <vs-navbar-item index="2" v-if="!isArchived && !postfundNavDisabled">
          <a>Post-fondeo</a>
        </vs-navbar-item>
        <a class="project-navbar-disabled" v-else>Post-fondeo</a>
      </template>
    </vs-navbar>

    
    <project-request-tab v-if="activeTab == requestTabIndex && isMounted" :id="id"
    :person_type="project.client.user.person_type" @on-request-complete="onRequestComplete" />
    <project-prefund-tab v-if="activeTab == prefundTabIndex && isMounted && !isArchived" :id="id"
    :applicantUserId="project.client.user_id" :project="project" />
    <project-postfund-tab v-if="activeTab == postfundTabIndex && isMounted && !isArchived" :id="id" />
    <vx-card>
      <ContractsPage v-if="activeTab == contractsTabIndex && isMounted && !isArchived" ref="contracts" @updated="getProjectInfo" :onboardingStepData="project" :isMoral="true" />
    </vx-card>
    <div ref="bottomEl"></div>
    <!-- <contracts-control v-if="activeTab == contractsTabIndex && isMounted && !isArchived" ref="contracts"
      :project_id="project.id" :isDisabled="!hasContracts" :id="id" /> -->

    <vs-prompt title="Archivar el proyecto" @cancel="closeArchivePrompt" @accept="doArchiveProject"
      @close="closeArchivePrompt" accept-text="Confirmar" cancel-text="Cancelar" :is-valid="validArchivePrompt"
      :active.sync="showArchivePrompt">
      <div class="con-exemple-prompt">
        <h6 class="mb-4">Por favor indícanos el motivo por el cual se elimina el proyecto:</h6>
        <p class="mb-2">¿Quién tomó la decisión?</p>
        <ul class="leftx mb-2">
          <li>
            <vs-radio v-model.number="actor" color="warning" vs-value="2">
              Instalador
            </vs-radio>
          </li>
          <li>
            <vs-radio v-model.number="actor" color="warning" vs-value="3">
              Solicitante
            </vs-radio>
          </li>
        </ul>

        <label for="" class="vs-input--label">Categoría</label>
        <v-select label="text" class="vs-custom" v-model.lazy="categorySelected" name="agent_id" :options="getCollection"
          :reduce="text => text.value" :clearable="false" :searchable="true" placeholder="Seleccione una opción"
          @change="getCollections()">
          <div slot="no-options">No hay coincidencias</div>
        </v-select>

        <label for="" class="vs-input--label">Motivo</label>
        <v-select label="text" class="vs-custom" v-model.lazy="resolutionSelected" name="agent_id"
          :options="collections.resolutions" :reduce="text => text.value" :clearable="false" :searchable="true"
          placeholder="Seleccione una opción">
          <div slot="no-options">No hay coincidencias</div>
        </v-select>

        <label v-if="isOther" for="" class="vs-input--label">Otro motivo</label>
        <vs-input v-if="isOther" placeholder="Describa" class="w-full" v-model="otherReasonText" />
      </div>
    </vs-prompt>

    <vs-popup title="Asignar vendedor al proyecto" :active.sync="showAssignAgentPrompt">
      <div class="con-exemple-prompt">

        <label for="" class="vs-input--label">Lista de vendedores disponibles</label>
        <v-select label="text" class="vs-custom" v-model.lazy="agent_id" name="agent_id" :options="getAgentsList"
          :reduce="text => text.value" :clearable="false" :searchable="true"
          :placeholder="getAgentsList.length < 1 ? noDataPlaceholder : selectPlaceholder"
          :disabled="getAgentsList.length < 1">
          <div slot="no-options">No hay coincidencias</div>
        </v-select>

        <vs-button :disabled="!agent_id" @click="saveAgent" color="primary" class="mr-5 mt-2">Asignar</vs-button>
        <vs-button @click="closeAgentPrompt" type="flat" color="primary">Cerrar</vs-button>
      </div>
    </vs-popup>

    <load-error v-if="failed" />

  </div>
</template>

<script>
import { mapState } from 'vuex';
import inputHelper from '@components/mixins/inputHelper';
import dateHelper from '@components/mixins/dateHelper';
import formHelper from '@components/mixins/formHelper';
import ProjectRequestTab from '@components/supplier/projects/ProjectRequestTab.vue'
import ProjectPrefundTab from '@components/supplier/projects/ProjectPrefundTab.vue'
import ProjectPostfundTab from '@components/supplier/projects/ProjectPostfundTab.vue'
import ContractsControl from '@projects/ContractsControl.vue'
import SingleProjectStatusPills from '@components/supplier/projects/SingleProjectStatusPills.vue'
import SplitProjectStatusPills from '@components/supplier/projects/SplitProjectStatusPills.vue'
import ProjectFundProgress from '@components/supplier/projects/ProjectFundProgress.vue'
import ProjectContractProgress from '@components/supplier/projects/ProjectContractProgress.vue'
import CreditEventsLog from '@components/supplier/projects/CreditEventsLog.vue'
import LoanRequestStepDetail from '@components/supplier/projects/LoanRequestStepDetail.vue';

import ContractsPage from '../../applicant/loan-requests/components/shared_components/ContractsPage.vue';

const headerFieldsDefinitions = [
  { id: 2, label: "ID del proyecto", field: "id", },
  { id: 4, label: "Estado", field: "status_named" },
  { id: 3, label: "Alias", field: "alias", },
  { id: 1, label: "ID Externo", field: "external_id" },
]

const tabActionsDefs = [
  { index: 0, name: 'solicitud' },
  { index: 1, name: 'prefondeo' },
  { index: 2, name: 'postfondeo' },
]

const resolutionCauseDefinitions = [
  { id: 1, label: "El cliente optó por otro crédito" },
  { id: 2, label: "El cliente lo pagó de contado" },
  { id: 3, label: "El cliente optó por no realizar el proyecto" },
  { id: 4, label: "Otra razón" },
];

const applicantCategory = [
  { value: "4", text: "Solicitante optó por otro crédito" }
  , { value: "5", text: "Solicitante lo pagó de contado" }
  , { value: "6", text: "Solicitante canceló el proyecto" }
  , { value: "7", text: "Solicitante no cuenta con requisito" }
  , { value: "8", text: "No le gustó el financiamiento de RedGirasol" }
  , { value: "9", text: "Solicitante no cuenta con obligado solicitado" }
  , { value: "10", text: "Solicitante no cuenta con enganche solicitado" }
  , { value: "11", text: "Ya no se tiene contacto con solicitante" }
];

const supplierCategory = [
  { value: "12", text: "No le gustó el financiamiento de RedGirasol" }
  , { value: "13", text: "Faltan condiciones de instalador" }
];

const otherResolutiondIds = [4, 6, 14, 20, 24, 29, 39, 41];

export default {
  mixins: [formHelper, inputHelper, dateHelper],
  props: ['id'],
  data() {
    return {
      isMounted: null,
      onError: null,
      errorCode: null,

      mainSecR1: [],
      mainSecR2: [],

      isMaster: null,

      failed: null,
      activeTab: 0,

      project: {},

      currentAction: null,

      requestTabIndex: 0,
      prefundTabIndex: 1,
      postfundTabIndex: 2,
      contractsTabIndex: 3,

      showArchivePrompt: false,
      resolutionCauseOptions: resolutionCauseDefinitions,
      resolutionClientCause: null,
      resolutionCause: "",

      showUnarchivePrompt: false,
      showAssignAgentPrompt: false,

      isArchived: false,
      agent_id: null,
      agent: null,
      selectPlaceholder: "Seleccione una opción",
      noDataPlaceholder: "No hay opciones disponibles",
      getAgentsList: [],
      allAgents: [],
      groupedContracts: [],
      contract_groups: [],
      serial_numbers: [],
      // Archivar de proyecto
      applicantCategory: applicantCategory,
      supplierCategory: supplierCategory,
      actor: 2,
      categorySelected: null,
      resolutionSelected: null,
      collections: {
        resolutions: []
      },
      otherResolutiondIds: otherResolutiondIds,
      otherReasonText: "",
      pfg_locations: [],
      technologyValidations: null,
    }
  },
  components: {
    ProjectRequestTab,
    ProjectPrefundTab,
    ProjectPostfundTab,
    SingleProjectStatusPills,
    SplitProjectStatusPills,
    ProjectFundProgress,
    CreditEventsLog,
    ContractsControl,
    ProjectContractProgress,
    ContractsPage,
    LoanRequestStepDetail
  },
  computed: {
    ...mapState("auth", ["user"]),
    userId: function () {
      return this.user.user_id;
    },
    showFundingProgress() {
      return this.project.status >= 5 && this.project.status <= 8;
    },
    canArchiveProject() {
      return this.project.status < 5 && !this.isArchived;
    },
    canUnarchiveProject() {
      // Se pueden desarchivar solo los proyectos que archivo el proveedor
      return this.resolution_client_cause != 0 && this.isArchived;
    },
    validArchivePrompt() {
      if (this.categorySelected === null || this.resolutionSelected === null) {
        return false;
      }
      if (this.isOther && this.otherReasonText.length < 0) {
        return false;
      }
      return true;
    },
    hasAgent() {
      return this.agent != null && this.agent.length != 0;
    },
    agentName() {
      return this.hasAgent ? this.agent.name : "Sin asignar";
    },
    isBackoffice() {
      return this.$acl.check('isBackoffice');
    },
    prefundNavDisabled() {
      return this.project.status < 4;
    },
    postfundNavDisabled() {
      return this.project.status < 5;
    },
    isSmallerScreen() {
      return this.$store.state.windowWidth <= 992
    },
    hasContracts() {
      return this.isMounted ? this.project.contracts.length > 0 : false;
    },
    hasContractGroup() {
      return this.project.contracts[0].contract_group_id != null ? true : false;
    },
    hasSerialNumbers() {
      if (this.isMounted && this.serial_numbers.length != 0) {
        this.serial_numbers.forEach(sn => {
          if (sn.serial_numbers_count < 1) {
            return false;
          }
        });
        return true;
      } else {
        return false;
      }
    },
    grouped_contracts() {
      if (this.isMounted) {
        let g1 = [];
        let g2 = [];
        let g3 = [];
        let g4 = [];
        let generic = [];
        let groupedContracts = [];
        this.project.contracts.forEach(contract => {
          switch (contract.contract_group_id) {
            case 1: g1.push(contract); break;
            case 2: g2.push(contract); break;
            case 3: g3.push(contract); break;
            case 4: g4.push(contract); break;
            default:
              generic.push(contract);
            // this.hasOldContracts = true;
          }
        });
        groupedContracts = [g1, g2, g3, g4, generic];
        // console.log(this.groupedContracts);
        return groupedContracts
      } else {
        return [];
      }
    },
    getCollection() {
      if (this.actor === 2) {
        return this.supplierCategory;
      }
      return this.applicantCategory;
    },
    isOther() {
      return this.otherResolutiondIds.some(x => x === this.resolutionSelected);
    },
    descText() {
      if(this.technologyValidations.validations.length > 0) {
        if(this.technologyValidations.validations[0].dataValidations.complete) {
          return "Gracias por haber proporcionado la información de la tecnología, continuaremos revisando la solicitud con tu cliente y se te notificara para su seguimiento.";
        }
        else {
          return "Por favor ayudanos a proporcionar la información relacionada con la tecnología y el documento con la cotización del proyecto.";
        }
      }
    },
    isMoral() {
      return this.project.client.user.person_type == 0 || this.project.client.user.person_type == 3;
    },
    isSFV() {
      return this.project.finance.loan_type == 1;
    },
    applicantName() {
      if(this.isMoral) {
        return this.project.client.user.business.name;
      } else {
        return `${this.project.client.user.personal.first_name} ${this.project.client.user.personal.last_name_1}`;
      }
    },
  },
  watch: {
    loading: function (newVal, oldVal) {
      if (newVal == true) {
        this.$vs.loading();
      } else {
        this.$vs.loading.close();
      }
    },
    categorySelected: function (event) {
      this.getCollections();
    }
  },
  async beforeMount() {
    this.loading = true;
    this.isMounted = false;
    await this.getProjectInfo();
    await this.getContractGroups();
    await this.getSerialNumbers();
    await this.loadSavedProjectFgLocations();
    this.setNavFromParams();
    this.loading = false;
    this.isMounted = true;
  },
  methods: {
    async onRequestComplete() {
      this.loading = true;
      await this.getProjectInfo();
      this.loading = false;
    },
    async assignAgentRequest() {
      this.agent_id = null;
      this.showLoading(true);
      this.getAgentsList = [];

      if (!this.isBackoffice) {
        let res2 = await axios.get("/api/supplier/agents");
        let options = res2.data;
        this.allAgents = options;
        options.forEach(f => {
          this.getAgentsList.push({ value: f.id, text: f.name });
        })
      }
      this.showLoading(false);
      this.showAssignAgentPrompt = true;
    },
    setNavFromParams() {
      if (this.isArchived) {
        this.activeTab = 0;
        return;
      }
      let nav = this.$route.query.nav;
      let t = 0;
      if (!nav) {
        if (this.project.status <= 3) {
          t = this.requestTabIndex;
        } else if (this.project.status == 4 ) {
          t = this.contractsTabIndex;
        } else if (this.project.status >= 5 && this.project.status <= 8 && this.isSFV) {
          t = this.postfundTabIndex;
        } else if (this.project.status >= 5 && this.project.status <= 8) {
          t = this.contractsTabIndex;
        }
      }
      else {
        if (nav && nav == "solicitud") {
          t = this.requestTabIndex;
        }
        else if (nav && nav == "prefondeo") {
          t = this.prefundTabIndex;
        }
        else if (nav && nav == "postfondeo") {
          t = this.postfundTabIndex;
        }
      }
      this.activeTab = t;

    },
    async getProjectInfo() {
      try {
        let response = await axios.get(`/api/v2/projects/${this.id}?
                                        with[]=splits.finance
                                        &with[]=client.user.business.personal
                                        &with[]=client.user.business.address
                                        &with[]=client.user.personal.address
                                        &with[]=agent
                                        &with[]=pdfInvoices
                                        &with[]=xmlInvoices
                                        &with[]=finance.guaranteesList.guaranteeProfile.personal.address
                                        &with[]=finance.guaranteesList.guaranteeProfile.business.address
                                        &with[]=finance.guaranteesList.guaranteeProfile.business.personal
                                        &with[]=contracts
                                        &with[]=splits.contracts
                                        &with[]=projectResolution.resolution`);
        //let response = await axios.get("/api/v1/supplier/projectInfo/" + this.id);
        this.onError = false;
        //this.project = response.data.project;
        this.project = response.data;
        this.isArchived = this.project.resolution_date !== null;
        this.isMaster = this.project.splits.length > 0;
        this.agent = this.project.agent;
        this.setBreadCrumbName();
      }
      catch (error) {
        this.processError(error.status);
        if (error.response.status === 403) {
          this.$router.replace('/mis-proyectos');
        }
      }
    },
    processError(code) {
      this.onError = true;
      this.errorCode = code;
    },
    loadFields() {
      let mainSection = headerFieldsDefinitions;
      let total = mainSection.length;
      let r1 = Math.ceil(total / 2);
      let c = 1;
      mainSection.forEach(e => {
        if (c <= r1) {
          this.mainSecR1.push(e);
        }
        else {
          this.mainSecR2.push(e);
        }
        c += 1;
      });
      this.loading = false;
      this.mounted = true;
    },
    fieldValue(field, format = null) {
      let val = this.getNestedObject(this.project, this.arrayFromFieldName(field));
      if (format != null && format != '') {
        return this.formatConvert(val, format);
      }
      else {
        return val || '-';
      }
    },
    formatConvert(val, format) {
      if (format == "loaded") {
        return (val == null || val == '') ? "Pendiente" : "Cargado";
      }
      else if (format == "date") {
        return (val == null || val == '') ? "-" : this.regularDateFormat(val);
      }
    },
    arrayFromFieldName(fname) {
      return fname.split('.');
    },
    getNestedObject(nestedObj, pathArr) {
      return pathArr.reduce((obj, key) =>
        (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
    },
    archiveProjectRequest() {
      let mssg = "¿Realmente desea archivar el proyecto?";
      mssg += " Podrá consultarlo posteriormente en la lista de proyectos archivados."

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Archivar proyecto',
        text: mssg,
        acceptText: "Archivar",
        cancelText: 'Cancelar',
        accept: this.archiveDialog
      });
    },
    archiveDialog() {
      //limpiar opciones
      this.resolutionClientCause = null;
      this.resolutionCause = "";
      this.showArchivePrompt = true;
    },
    async doArchiveProject() {
      this.loading = true;
      let obj = { resolution_id: this.resolutionSelected, resolution_other: this.otherReasonText };

      try {
        let res = await axios.post(`/api/project/${this.id}/archive`, obj);
        this.$router.go();

      } catch (error) {
        this.$vs.notify({
          color: 'danger',
          title: 'Error',
          text: 'Ha ocurrido un error en la operación, intente más tarde'
        })
      }
      this.loading = false;
    },
    closeArchivePrompt() {
      this.resolutionSelected = null;
      this.categorySelected = null;
      this.otherReasonText = "";
      this.collections.resolutions = [];
      this.showArchivePrompt = false
    },
    closeAgentPrompt() {
      this.showAssignAgentPrompt = false
    },
    async saveAgent() {
      if (this.agent_id == this.agent.id) {
        this.closeAgentPrompt();
        return;
      }

      this.showLoading(true);
      try {
        let payload = { agent_id: this.agent_id, project_id: this.id };
        await axios.post('/api/v1/supplier/setSupplierAgentToProject', payload);
        this.agent = this.allAgents.find(f => f.id == this.agent_id);
        this.closeAgentPrompt();
        this.saveSuccessNotif();
      }
      catch (error) {
        this.warn(error);
        this.failedOperationNotif();
      }
      this.showLoading(false);
    },
    shortDesc: function () {
      let desc = "";
      if (this.project.status != null) {
        desc = "Tu proyecto ";
        switch (this.project.status) {
          case 1:
            desc += "se encuentra en proceso de creación"
            break;
          case 2:
            desc += "se encuentra en revisión. Te notificaremos al concluir con la revisión. Puedes continuar llenando la solicitud si así lo deseas";
            break;
          case 3:
            desc += "está preaprobado";
            break;
          case 4:
            desc += "está aprobado";
            break;
          case 5:
            desc += "se encuentra en fondeo";
            break;
          case 6:
            desc += "está en firma de contratos";
            break;
          case 7:
            desc += "se encuentra en instalación";
            break;
          case 8:
            desc += "ha sido entregado";
            break;
          case 9:
            desc += "ha sido rechazado";
            break;
          case 10:
            desc += "fue reembolsado";
            break;
          default:
            desc += "está pendiente";
            break;
        }
        desc += ".";
      }
      return desc;
    },
    archiveReason() {
      const resolutions = this.project.project_resolution;

      if (resolutions !== null && resolutions.length !== 0) {
        let isOther = this.otherResolutiondIds.some(x => x === resolutions[0].resolution_id);
        if (isOther) {
          return resolutions[0].description;
        } else {
          return resolutions[0].resolution.description;
        }
      }
    },
    unarchiveProjectRequest() {
      let proj = this.project;
      let mssg = "¿Realmente desea desarchivar el proyecto " + proj.id + "?";

      if (proj.step != 4) {
        mssg += " El proyecto se desarchivará con el estado que tenía.";
      }
      else {
        // RNs 
        // si tenia menos de 30 dias aprobado, se desarchiva como aprobado,
        // si tenia mas de 30 dias aprobado, se desarchiva como pre-aprobado
        if (proj.approved_days <= 30) {
          mssg += " El proyecto se desarchivará en estado aprobado.";
        }
        else {
          mssg += " El proyecto tenía más de 30 días de aprobado, por lo que se desarchivará pero volverá a estado pre-aprobado.";
        }
      }

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Desarchivar proyecto',
        text: mssg,
        acceptText: "Desarchivar",
        cancelText: 'Cancelar',
        accept: this.doUnarchiveProject
      });
    },
    async doUnarchiveProject() {
      this.loading = true;
      let obj = { project: this.project.id };
      try {
        await axios.post('/api/v1/supplier/unarchiveProject', obj);
        this.$router.go();
      }
      catch (error) {
        this.$vs.notify({
          color: 'danger',
          title: 'Error',
          text: 'Ha ocurrido un error en la operación, intente más tarde'
        });
      }
      this.loading = false
    },
    closeUnarchivePrompt() {
      this.showUnarchivePrompt = false
    },
    getContractGroups() {
      axios.get('/api/catalogs/contract_groups').then(response => {
        this.contract_groups = response.data.data;
      });
    },
    getSerialNumbers() {
      axios.get(`/api/v1/fg_locations/${this.id}/getSerialNumbers`).then(response => {
        this.serial_numbers = response.data;
        // console.log(response.data);
      });
    },
    async getCollections() {
      try {
        const res = await axios.get(`/api/v1/forms/getResolutionByCategory/${this.categorySelected}`);
        let data = res.data;
        this.collections.resolutions = [];
        this.resolutionSelected = null;

        data.forEach(element => {
          let obj = {
            value: element.id,
            text: element.description
          }
          this.collections.resolutions.push(obj);
        });

      }
      catch (e) {
        this.warn(e);
      }
    },
    setBreadCrumbName() {
      const crumb = document.getElementsByClassName("cursor-default");
      if (crumb.length > 0) {
        crumb[0].innerText = `${this.project.alias || "Alias"} - ${this.project.id}`;
      }
    },
    async loadSavedProjectFgLocations(){
      try {
        const res = await axios.get(`/api/v1/project/${this.project.id}/getProjectFgLocations`);
        this.pfg_locations = res.data;

        const resValidations = await axios.get(`/api/v1/project/${this.project.id}/verifyProjectTechnologies`);
        this.technologyValidations = resValidations.data;
      }
      catch (e) {
        this.warn(e);
      }
    },
    goToPage(index)
    {
      this.isMounted = false;
      this.activeTab = index;
      this.$refs.bottomEl?.scrollIntoView({ behavior: 'smooth' });
      this.isMounted = true;
    }
  }
}
</script>

<style>
.rg-custom-title {
  color: #2D2D2D;
  font-size: 3rem;
}
  .rg-custom-title span {
    color: #28DE18;
  }

.project-navbar {
  display: grid;
  background-color: aqua;
}
</style>